import React, { useState } from 'react';
import Typography from 'antd/es/typography';
import { Button } from 'antd';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import InputFieldComponent from '../../components/InputField/InputField';
import { Container } from './style';
import { motion } from 'framer-motion';
import { resetPassword } from '../../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('login');
  const { userId, email } = useParams<{userId: string, email: string}>();
  const [userFields, setUserFields] = useState<{password: string}>({
    password: ''
  });
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputField = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setIsPasswordError(false);
    setPasswordErrors([]);
    setUserFields({...userFields, [name]: value});
  };
    
  const handleForgotPassword = () => {
    if (validateInputFields())
      resetPassword(userFields, {userId: userId as string, email: email as string})
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, ToastConfig);
            navigate('/login');
          }
          else toast.error(t('resetPasFail'), ToastConfig);
        })
        .catch(() => toast.error('Application Error', ToastConfig));
  };

  const validateInputFields = () => {
    // Email validation
    let isValidData = true;
    const passwordRegexLowerCase = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegexLowerCase.test(userFields.password)) {
      setIsPasswordError(true);
      setPasswordErrors(
        [t('validationPassword')]
      );
      isValidData = false;
    }
    
    return isValidData;
  };


  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <div className='content'>
          <Title level={3}>{t('reset')}</Title>
          <Text className='info-text' style={{ fontSize: '1.1rem'}}>{t('emailReset')}</Text>
          <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem'}}>
            <div>
              <FormGroupComponent
                className='label-animation'
                hasError={isPasswordError}
                errors={passwordErrors}
              >
                <InputFieldComponent
                  placeholder='Password'
                  name='password'
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={userFields.password}
                  onChange={handleInputField}
                  required
                />
                {
                  isPasswordVisible ? (
                    <AiFillEyeInvisible className='icon' onClick={() =>setIsPasswordVisible(false)} fontSize={20}/>
                  ): (
                    <AiFillEye className='icon' onClick={() =>setIsPasswordVisible(true)} fontSize={20}/>
                  )
                }
              </FormGroupComponent>
            </div>
            <FormGroupComponent className='auth-btn-group'>
              <Button className='btn btn-primary' onClick={handleForgotPassword}>{t('reset')}</Button>
            </FormGroupComponent>
          </form>
        </div>
      </Container>
    </motion.div>
  );
};

export default ResetPassword;
