import logoSvg from './logo.svg';
import logo2Svg from './logo-2.svg';
import footballField from './football-field.png';
import homeIllustration from './HomeIllustration3.png';
import AccountIllustration from './HomeIllustration.png';

export const Images = {
  logoSvg,
  logo2Svg,
  footballField,
  homeIllustration,
  AccountIllustration
};
