import React, { useState } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
// import { BsLink45Deg } from 'react-icons/bs';
import { Button, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import Form from '../../components/Form/Form';
import { useNavigate } from 'react-router-dom';
import { createTeamApi } from '../../api/teamApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-types
const CreateTeam = () => {
  const { t } = useTranslation('createTeam');
  const navigate = useNavigate();
  const [team, setTeam] = useState();
  const [name, setName] = useState<string>('');
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedSubscription, setSelectedSubscription] = useState<string>();
  const { user, updateUserState } = useStateContext();
    
  const handleCreateTeam = () => {
    let subscriptionType, subscriptionAmount;

    console.log(selectedSubscription);

    switch (selectedSubscription) {
    case 'gold':
      subscriptionAmount = 15000;
      subscriptionType = 'gold';
      break;
    case 'silver':
      subscriptionAmount = 10000;
      subscriptionType = 'silver';
      break;
    case 'platinium':
      subscriptionAmount = 20000;
      subscriptionType = 'platinium';
      break;
    default:
      subscriptionAmount = 0;
      subscriptionType = 'base';
                
    }
    const data = { name: name as string, subscriptionType, subscriptionAmount }; 
    createTeamApi(data)
      .then((response) => {
        if (response.status === 201) {
          // setIsModalOpen(true);
          updateUserState && updateUserState(response.data.user);
          setTeam(response.data.data);
          navigate('/onboarding');
          toast.success('Team Created', ToastConfig);
        } else {
          toast.error(`${response.data.message}`, ToastConfig);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Application Error', ToastConfig);
      });
  };
    
  // const handleOk = () => {
  //   navigate('/portal');
  // };

  return (
    <div>
      {/* <Modal
        title={(<Title level={4}><span>Invite other team members</span></Title>)}
        open={isModalOpen}
        footer={<div><Button className='btn btn-secondary' onClick={handleOk}>OK</Button></div>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '2rem'}}>
          <p className='top-text' style={{ padding: 0, lineHeight: '3rem' }}>
                If you would like to invite others to join your team, we have made it easy for you to do so.
                By clicking <b>copy link</b>, you will receive a link to invite others to your team.
                Once you click <b>OK</b>, you will be directed to your portal</p>
          <Form>
            <Button style={{ background: 'var(--color-text-in-secondary)', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem', padding: '2rem 0' }}>
              <BsLink45Deg fontSize={25} />Copy Link
            </Button>
          </Form>
        </div>
      </Modal> */}
      {
        !team && (
          <div style={{ padding: '2rem 0' }}>
            <Title level={5}>
              <span className='capitalize'>{t('createteam')}</span>
            </Title>
            <div className='flex flex-col gap-5 mb-5'>
              <p className='info-onboarding-text'>{t('plan')}</p>
              <p className='info-onboarding-text'><strong>Platinum:</strong> {t('platinum')}</p>
              <p className='info-onboarding-text'><strong>Gold:</strong> {t('gold')}</p>
              <p className='info-onboarding-text'><strong>Silver:</strong> {t('silver')}</p>
              <p className='info-onboarding-text'><strong>{t('none')}:</strong> {t('none2')}</p>
            </div>
            <p className='info-onboarding-text capitalize mb-10'>{t('plan2')}</p>
            <Form>
              <div className='form-group'>
                <IoIosPeople className="select-icon select-icon-left"/>
                <input className='input-field' name="name" type="text" placeholder="Team Name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className='form-group'>
                <FaRegCalendarAlt className="select-icon select-icon-left"/>
                <Select className="select-field" style={{ width: '100%',   overflow: 'auto' }} onChange={setSelectedSubscription} value={selectedSubscription}>
                  <Select.Option value="none">{t('none')}</Select.Option>
                  <Select.Option value="platinium">Platinum</Select.Option>
                  <Select.Option value="gold">Gold</Select.Option>
                  <Select.Option value="silver">Silver</Select.Option>
                </Select>
              </div>
            </Form>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
              <Button className='custom-btn-ghost' onClick={() => navigate('/onboarding/join-team')}><span>{t('jointeam')}</span></Button>
              <Button className='custom-btn' onClick={() => handleCreateTeam()}><span>{t('createteam')}</span></Button>
            </div>
            {
              !!(user?.teams?.length) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
                <Button
                  className='custom-btn-ghost'
                  onClick={() => navigate('/onboarding')}
                >
                  <span>{t('next')}</span>
                </Button>
              </div>
            }
          </div>
        )
      }
    </div>
  );
};

export default CreateTeam;
