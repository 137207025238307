import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { Button } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import InputFieldComponent from '../../components/InputField/InputField';
import { Container } from './style';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { continueWithGoogle, signup } from '../../api/userApi';
import { toast } from 'react-toastify';
import config, { ToastConfig } from '../../config/config';
import { gapi } from 'gapi-script';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

interface userType { email: string, password: string, firstName: string, lastName: string}

const AccountLogin = () => {
  const { t } = useTranslation('register');
  const navigate = useNavigate();
  const [userFields, setUserFields] = useState<userType>({
    email: '', password: '', firstName: '', lastName: ''
  });
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isValidFirstName, setIsValidFirstName] = useState<boolean>(true);
  const [isValidLastName, setIsValidLastName] = useState<boolean>(true);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [textErrors, setTextErrors] = useState<string[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const { updateUserState } = useStateContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputField = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    switch (name) {
    case 'password':
      setIsPasswordError(false);
      setPasswordErrors([]);
      break;
    case 'email':
      setIsEmailError(false);
      setEmailErrors([]);
      break;
    case 'firstName':
      setIsValidFirstName(true);
      break;
    case 'lastName':
      setIsValidLastName(true);
      break;
    }
    setUserFields({...userFields, [name]: value});
  };

  const handleFieldsValidation = () => {
    // Email validation
    let isValidData = true;

    if (!userFields.firstName) {
      setIsValidFirstName(false);
      setTextErrors([t('Fieldfill')]);
      isValidData = false;
    }

    if (!userFields.lastName) {
      setIsValidLastName(false);
      setTextErrors([t('Fieldfill')]);
      isValidData = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userFields.email)) {
      setIsEmailError(true);
      setEmailErrors([t('validationPassword')]);
      isValidData = false;
    }

    const passwordRegexLowerCase = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegexLowerCase.test(userFields.password)) {
      setIsPasswordError(true);
      setPasswordErrors(
        [t('validationPassword')]
      );
      isValidData = false;
    }
    
    return isValidData;
  };
    
  const handleRegistration = () => {
    if (handleFieldsValidation())
      signup(userFields)
        .then((response) => {
          if (response.status === 201) {
            updateUserState && updateUserState(response.data.user);
            navigate('/account-verification');
          }
          else toast.error(`${response.data.message}`, ToastConfig);
        })
        .catch(err => console.error(err));
  };

  const buttonRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = async (response: any) => {
    const token = response?.xc?.access_token;
    if (token)
      continueWithGoogle({ token })
        .then((response) => {
          if (response.status === 200) {
            navigate('/portal');
            updateUserState && updateUserState(response.data.user); 
          } else {
            toast.info('Login With Google Failed');
          }
        });
  };

  const onFailure = () => {
    toast.error( t('Googlefail') , ToastConfig);
  };

  const handleGoogleButton = () => {
    gapi.auth2.getAuthInstance().signIn().then(onSuccess, onFailure);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: config.google.clientId,
        scope: 'profile email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <div className='content'>
          <Title level={3}> {t('createAccount') }</Title>
          <Text className='info-text' style={{ fontSize: '1.1rem'}}> {t('bookingTitle') }</Text>
          <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem'}}>
            <FormGroupComponent
              className='label-animation'
              hasError={!isValidFirstName}
              errors={textErrors}
            >
              <InputFieldComponent
                placeholder={t('FirstName') }
                name='firstName'
                type='text'
                value={userFields.firstName}
                onChange={handleInputField}
                required
              />
            </FormGroupComponent>
            <FormGroupComponent
              className='label-animation'
              hasError={!isValidLastName}
              errors={textErrors}
            >
              <InputFieldComponent
                placeholder={t('LastName') }
                name='lastName'
                type='text'
                value={userFields.lastName}
                onChange={handleInputField}
                required
              />
            </FormGroupComponent>
            <FormGroupComponent
              className='label-animation'
              hasError={isEmailError}
              errors={emailErrors}
            >
              <InputFieldComponent
                placeholder={t('EmailPlaceholder') }
                name='email'
                type='email'
                value={userFields.email}
                onChange={handleInputField}
                required
              />
            </FormGroupComponent>
            <FormGroupComponent
              className='label-animation'
              hasError={isPasswordError}
              errors={passwordErrors}
            >
              <InputFieldComponent
                placeholder={t('PasswordPlaceholder') }
                name='password'
                type={isPasswordVisible ? 'text' : 'password'}
                value={userFields.password}
                onChange={handleInputField}
                required
              />
              {
                isPasswordVisible ? (
                  <AiFillEyeInvisible className='icon' onClick={() =>setIsPasswordVisible(false)} fontSize={20}/>
                ): (
                  <AiFillEye className='icon' onClick={() =>setIsPasswordVisible(true)} fontSize={20}/>
                )
              }

            </FormGroupComponent>
            <FormGroupComponent className='auth-btn-group'>
              <Button
                className='btn btn-primary'
                onClick={handleRegistration}
              >
                {t('continueEmail') }
              </Button>
              <Button
                ref={buttonRef}
                onClick={handleGoogleButton}
                className="google-btn btn"
              >
                <FcGoogle fontSize={20} />
                {t('continueGoogle') }
              </Button>
            </FormGroupComponent>
          </form>
          <p style={{ textAlign: 'center', fontSize: '1.3rem' }}>
            {t('HaveAccount') }
            {' '}
            <Link
              to={'/login'}
              style={{ fontWeight: 600, textDecoration: 'none', color: 'black' }}
            >
              {t('secondaryTitle')}
            </Link>
          </p>
        </div>
      </Container>
    </motion.div>
  );
};

export default AccountLogin;
