import api from './../services/axios';

export const getAllEventsByTeam = async(team: { teamId: string}) => {
  try {
    return await api.get(`/slot/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllPaidEventsByTeam = async(team: { teamId: string}) => {
  try {
    return await api.get(`/slot/paid/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllUnpaidEventsByTeam = async(team: { teamId: string}) => {
  try {
    return await api.get(`/slot/unpaid/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllCancelledEventsByTeam = async(team: { teamId: string}) => {
  try {
    return await api.get(`/slot/cancel/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const payBill = async(bill: { billId: string}) => {
  try {
    return await api.patch(`/bill/${bill.billId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};