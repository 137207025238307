/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Empty, Radio, RadioChangeEvent, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { FooterSection } from './style';
import { IoCalendarOutline } from 'react-icons/io5';
import { Form } from '../../components/Form/styles';
import { IoIosPeople } from 'react-icons/io';
import ModalComponent from '../../components/Modal/ModalComponent';
import { bookSlot, getAllSlotsPerDay } from '../../api/eventApi';
import { getAllTeams } from '../../api/teamApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { userTeams } from '../../api/userApi';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Loading/Spinner';
import { useNavigate } from 'react-router-dom';

const PortalPage = () => {
  const { t } = useTranslation('booksession');
  const [isPlayingWithTeam, setIsPlayingWithTeam] = useState<boolean>(false);
  const [isConditionChecked, setIsConditionChecked] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [events, setEvents] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedSlot, setSelectedSlot] = useState<any>({ _id: '' });
  const [teams, setTeams] = useState([]);
  const [selectedLeftTeam, setSelectedLeftTeam] = useState<any>();
  const [selectedTeam, setSelectedTeam] = useState<any>({});
  const [userExistingTeams, setUserExistingTeams] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onChange = (e: RadioChangeEvent) => {
    setIsPlayingWithTeam(e.target.value);
  };

  const handleConditionChanged = () => {
    setIsConditionChecked(!isConditionChecked);
  };

  const handleBookTime = () => {
    switch (isConditionChecked) {
    case true:
      if (!selectedSlot?._id)
        return toast.info('Please pick a slot', ToastConfig);
      if (Object.entries(selectedTeam).length === 0)
        return toast.info('Please select your team', ToastConfig);
  
      setIsLoading(true);
      bookSlot({ teamId: selectedTeam, leftTeamId: selectedLeftTeam, slotId: selectedSlot?._id })
        .then((response) => {
          if (response.status === 200) {
            toast.success('Success', ToastConfig);
            navigate('/portal/payment');
          } else {
            toast.error(response.data.message);
          }
        }).finally(() => {setIsLoading(false);});
      // setIsModalOpen(true);
      break;
    default:
      toast.info('Please agree to terms and conditions', ToastConfig);
      break;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFindEvents = (e: any) => {
    const day = e?.startOf('day').format('YYYY-MM-DD');
    setSelectedSlot({ _id: '' });
    getAllSlotsPerDay({ day })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.data?.slots) {
            const slots = response.data.data.slots.filter((slot: any) => !slot.isBooked);
            response.data.data.slots = slots;
            setEvents(response.data.data); 
          } else {
            setEvents(response.data.data);  
          }
        } else {
          setEvents(null);
        }
      });
  };

  const handleTime = (dateString: string) => {
    if (!dateString) return;
    const date = new Date(dateString);
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
  };

  useEffect(() => {
    if (!teams.length)
      getAllTeams()
        .then((response) => {
          if (response.status === 200) {
            setTeams(response.data.data || []);
          }
        });
  }, []);

  useEffect(() => {
    if (!userExistingTeams.length)
      userTeams()
        .then((response) => {
          if (response.status === 200) {
            setUserExistingTeams(response.data.data.teams || []);
          }
        });
  }, []);
  
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
      <ModalComponent open={isModalOpen} handleOk={closeModal} handleCancel={closeModal} loading={false}>
        <p>{t('reservationsuccess')}</p>
      </ModalComponent>
      <Title level={4} className='info-title p-0 mb-0'>{t('sessionshedule')}</Title>
      <Title level={5} className='info-title'>{t('selectD')}</Title>
      <DatePicker className='date-picker' onChange={(date) => handleFindEvents(date)} />
      <div>
        <Title level={5}>{t('selectT')}</Title>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '1.5rem' }}>
          {
            isLoading && <Spinner />
          }
          {!isLoading && events && events?.slots?.length ? (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            events.slots.map((slot: any) => {
              const startTime = handleTime(slot.startTime);
              const endtime = handleTime(slot.endTime);
              return (
                <Button
                  onClick={() => setSelectedSlot(slot)}
                  className={`${selectedSlot._id === slot._id ? 'custom-btn' : 'custom-btn-ghost'}`}
                  key={slot._id}
                >
                  {`${startTime} - ${endtime}`}
                </Button>
              );
            })
          ) : (!isLoading && (
            <div className='flex w-full items-center justify-center'>
              <Empty className='italic flex flex-col items-center justify-center' />
            </div>))}
        </div>
      </div>
      <div>
        <Title level={5}>{t('playingagainst')}?</Title>
        <Radio.Group onChange={onChange} value={isPlayingWithTeam}>
          <Radio value={true} className='capitalize'>{t('yes')}</Radio>
          <br/>
          <Radio value={false} className='capitalize'>{t('no')}</Radio>
        </Radio.Group>
      </div>
      <div>
        <Title level={5}>{t('selectteam')}</Title>
        <Form>
          <div className='form-group'>
            <IoIosPeople className="select-icon select-icon-left"/>
            <Select className="select-field" style={{ width: '100%' }} onChange={setSelectedTeam}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                userExistingTeams && userExistingTeams.map((team: any) => (
                  <Select.Option key={team._id} value={team._id}>{team.name }</Select.Option>
                ))
              }
            </Select>
          </div>
        </Form>
      </div>
      {
        isPlayingWithTeam && (
          <div>
            <Title level={5}>{t('selectteam2')}</Title>
            <Form>
              <div className='form-group'>
                <IoIosPeople className="select-icon select-icon-left"/>
                <Select className="select-field" style={{ width: '100%' }} onChange={setSelectedLeftTeam}>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    teams && teams.map((team: any) => (
                      <Select.Option key={team._id} value={team._id}>{team.name }</Select.Option>
                    ))
                  }
                </Select>
              </div>
            </Form>
          </div>
        )
      }
      <FooterSection>
        <div className='booking-btn-box'>
          <Checkbox checked={isConditionChecked} onChange={handleConditionChanged} className='text-xl'>
            {t('terms2')}
          </Checkbox>
          <Button className='custom-btn' onClick={handleBookTime} >
            <IoCalendarOutline />
            <span className='text'>{t('book')}</span>
          </Button>
        </div>
      </FooterSection>
    </div>
  );
};

export default PortalPage;