import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { Button } from 'antd';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import InputFieldComponent from '../../components/InputField/InputField';
import { motion } from 'framer-motion';
import { signupAdmin } from '../../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

interface userType { email: string, password: string, firstName: string, lastName: string}

const AdminCreateAccount = () => {
  const { t } = useTranslation('register');
  const navigate = useNavigate();
  const [userFields, setUserFields] = useState<userType>({
    email: '', password: '', firstName: '', lastName: ''
  });
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isValidFirstName, setIsValidFirstName] = useState<boolean>(true);
  const [isValidLastName, setIsValidLastName] = useState<boolean>(true);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [textErrors, setTextErrors] = useState<string[]>([]);
  const { updateUserState } = useStateContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputField = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    switch (name) {
    case 'email':
      setIsEmailError(false);
      setEmailErrors([]);
      break;
    case 'firstName':
      setIsValidFirstName(true);
      break;
    case 'lastName':
      setIsValidLastName(true);
      break;
    }
    setUserFields({...userFields, [name]: value});
  };

  const handleFieldsValidation = () => {
    // Email validation
    let isValidData = true;

    if (!userFields.firstName) {
      setIsValidFirstName(false);
      setTextErrors([t('Fieldfill')]);
      isValidData = false;
    }

    if (!userFields.lastName) {
      setIsValidLastName(false);
      setTextErrors([t('Fieldfill')]);
      isValidData = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userFields.email)) {
      setIsEmailError(true);
      setEmailErrors([t('validationPassword')]);
      isValidData = false;
    }
    
    return isValidData;
  };
    
  const handleRegistration = () => {
    if (handleFieldsValidation())
      signupAdmin(userFields)
        .then((response) => {
          if (response.status === 201) {
            toast.success('Admin created');
          }
          else toast.error(`${response.data.message}`, ToastConfig);
        })
        .catch(err => console.error(err));
  };

  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className='content'>
        <Title level={3}>Add a new Admin</Title>
        <Text className='info-text' style={{ fontSize: '1.1rem'}}>Add a new admin to manage the platform</Text>
        <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem'}}>
          <FormGroupComponent
            className='label-animation'
            hasError={!isValidFirstName}
            errors={textErrors}
          >
            <InputFieldComponent
              placeholder={t('FirstName') }
              name='firstName'
              type='text'
              value={userFields.firstName}
              onChange={handleInputField}
              required
            />
          </FormGroupComponent>
          <FormGroupComponent
            className='label-animation'
            hasError={!isValidLastName}
            errors={textErrors}
          >
            <InputFieldComponent
              placeholder={t('LastName') }
              name='lastName'
              type='text'
              value={userFields.lastName}
              onChange={handleInputField}
              required
            />
          </FormGroupComponent>
          <FormGroupComponent
            className='label-animation'
            hasError={isEmailError}
            errors={emailErrors}
          >
            <InputFieldComponent
              placeholder={t('EmailPlaceholder') }
              name='email'
              type='email'
              value={userFields.email}
              onChange={handleInputField}
              required
            />
          </FormGroupComponent>
          <FormGroupComponent className='auth-btn-group'>
            <Button
              className='btn btn-primary'
              onClick={handleRegistration}
            >
              {t('continueEmail') }
            </Button>
          </FormGroupComponent>
        </form>
      </div>
    </motion.div>
  );
};

export default AdminCreateAccount;
