import Title from 'antd/es/typography/Title';
import React from 'react';
import { Images } from '../../assets';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LandingPortal = () => {
  const { t } = useTranslation('landingPage');
  const navigate = useNavigate();
  return (
    <div>
      <Title level={5}>Canal Soccer Portal</Title>
      <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '3rem', alignItems: 'center', justifyContent: 'center'}}>
        <img src={Images.homeIllustration} width="150rem"/>
        <p style={{ fontSize: '1.2rem', lineHeight: '2rem'}}>
          {t('landing')}!
        </p>
        <Button className='custom-btn' onClick={() => navigate('/portal/book-session')}>{t('sbooking')}</Button>
      </div>
    </div>
  );
};

export default LandingPortal;