export function splitTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return {
    day: `${year}-${month}-${day}`,
    hour: `${hours}:${minutes}:${seconds}`,
  };
}