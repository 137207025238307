/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Collapse, DatePicker, Empty } from 'antd';
import Title from 'antd/es/typography/Title';
import { getAllSlotsPerDay } from '../../api/eventApi';
import LoadingScreen from '../../layouts/LoadingScreen';

function formattedDateTime(eventTime: string) {
  if (eventTime) {
    const [date, time] = eventTime.split('T');
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const [hour, minute] = time.slice(0, -5).split(':');
    const formattedTime = `${hour}:${minute}`;

    return { formattedTime, formattedDate}; 
  }
}

const BillPanelHeader = ({ time }: { time: string}) => {
  return <span style={{ fontSize: '1.25rem', lineHeight: '2rem', fontWeight: 500, color: 'black' }}>{ time}</span>;
};

const AdminUpcomingEvents = () => {
  const [events, setEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFindEvents = (e: any) => {
    const day = e?.startOf('day').format('YYYY-MM-DD');
    setIsLoading;
    getAllSlotsPerDay({ day })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.data?.slots) {
            const slots = response.data.slots.filter((slot: any) => slot.isBooked);
            setEvents(slots); 
          } else {
            setEvents(response.data.data);  
          }
        } else {
          setEvents(null);
        }
      }).finally(() => { setIsLoading(false);});
  };
    
  if (isLoading)
    return <LoadingScreen />;

  return (
    <div>
      <Title level={4} className='info-title p-0 mb-0'>View upcoming events</Title>
      <Title level={5} className='info-title'>Select Date</Title>
      <DatePicker className='date-picker w-full' onChange={(date) => handleFindEvents(date)} />
          
      <div className='mt-10'>
        {
          events ? (events as any)?.map((event: any) => {
            return (
              <Collapse key={event._id} collapsible='header' style={{ width: '100%', marginTop: '3rem'}}>
                <Collapse.Panel header={<BillPanelHeader time={formattedDateTime(event.startTime)?.formattedTime as string} />} key="1">
                  <p style={{ fontSize: '1.15rem', lineHeight: '2rem', fontWeight: 400 }}><span className='font-medium italic'>{event?.teamId?.name} </span>- VS -<span className='font-medium italic'>{ event?.leftTeamId?.name}</span></p>
                </Collapse.Panel>
              </Collapse>
            );

          })
            :
            <Empty className="italic" />
        }
      </div>
    </div>
  );
};

export default AdminUpcomingEvents;