import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminContent, AdminWrapper } from './style';

const Admin = () => {
  return (
    <AdminWrapper>
      <AdminContent>
        <Outlet />
      </AdminContent>
    </AdminWrapper>
  );
};

export default Admin;