import api from './../services/axios';

export const getAllTeams = async () => {
  try {
    const response = await api.get('/team');
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTeamByIdHandler = async (team: { id: string }) => {
  try {
    return await api.get(`/team/${team.id}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createTeamApi = async (team: {name: string, subscriptionType: string, subscriptionAmount: number}) => {
  try {
    const response = await api.post('/team', team);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const joinTeamHandler = async (team: {teamId: string }) => {
  try {
    const response = await api.patch(`/team/join/${team.teamId}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const leaveTeamHandler = async (team: { teamId: string }) => {
  try {
    return await api.patch(`/team/leave/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const activatePlayerHandler = async (teamId: string, playerId: string) => {
  try {
    return await api.patch('/team/approve', { teamId, userId: playerId});
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const removePlayerHandler = async (teamId: string, playerId: string) => {
  try {
    return await api.patch(`/team/remove/${teamId}`, { userId: playerId});
  } catch (error) {
    console.error(error);
    throw error;
  }
};