import api from './../services/axios';

export const scheduleEventHandler = async (event: { day: string, slotsData: unknown[]}) => {
  try {
    const response = await api.post('/event/save-event', event);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllSlotsPerDay = async (event: { day: string }) => {
  try {
    const response = await api.get(`/event/${event.day}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const bookSlot = async (event: { teamId: string, leftTeamId?: string, slotId: string }) => {
  try {
    const response = await api.patch('/event/', event);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const upcomingEvents = async(team: { teamId: string}) => {
  try {
    return await api.get(`/event/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const verifyQrCodeHandler = async(qrcode: string, teamId: string, slotId: string, userId: string) => {
  try {
    return await api.get(`/event/verify/${qrcode}/${teamId}/${slotId}/${userId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};