import React from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { useLanguageContext } from '../../context/LanguageContext';
import { IoIosArrowDown } from 'react-icons/io';

const LocalesComponent = ({ style }: {style?: object}) => {
  const { language, changeLanguage } = useLanguageContext();
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button onClick={() => changeLanguage && changeLanguage('en')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)'}}>
          <span>🇺🇸</span><span>English</span>
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button onClick={() => changeLanguage && changeLanguage('rw')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
          <span>🇷🇼</span><span>Kinyarwanda</span>
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button onClick={() => changeLanguage && changeLanguage('fr')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
          <span>🇫🇷</span><span>Francais</span>
        </Button>
      ),
    },
  ];
  return (
    <Button style={{ background: 'none', border: 'none', boxShadow: 'none', ...style}} className='btn-lang'>
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {(language as string).toLocaleUpperCase()}
            <IoIosArrowDown />
          </Space>
        </a>
      </Dropdown>
    </Button>
  );
};

export default LocalesComponent;