import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { Button } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import InputFieldComponent from '../../components/InputField/InputField';
import { Container } from './style';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import {gapi} from 'gapi-script';
import { motion } from 'framer-motion';
import config, { ToastConfig } from '../../config/config';
import { continueWithGoogle, login } from '../../api/userApi';
import { toast } from 'react-toastify';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const AccountLogin = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const [userFields, setUserFields] = useState<{email: string, password: string}>({
    email: '', password: ''
  });
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const { updateUserState } = useStateContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = async (response: any) => {
    const token = response?.xc?.access_token;
    if (token)
      continueWithGoogle({ token })
        .then((response) => {
          if (response.status === 200) {
            switch (response.data.user.role) {
            case 'player':
              navigate('/portal');
              break;
            case 'admin':
              navigate('/admin/events');
              break;
            default:
              toast.info('invalid user', ToastConfig);
              navigate('/login');
            }
            updateUserState && updateUserState(response.data.user); 
          } else {
            toast.info('Login With Google Failed');
          }
        });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputField = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    switch (name) {
    case 'password':
      setIsPasswordError(false);
      setPasswordErrors([]);
      break;
    case 'email':
      setIsEmailError(false);
      setEmailErrors([]);
      break;
    }
    setUserFields({...userFields, [name]: value});
  };
    

  const onFailure = () => {
    toast.error( t('Googlefail') , ToastConfig);
  };
    
  const handleLogin = () => {
    if (validateInputFields())
      login(userFields)
        .then((response) => {
          if (response.status === 200) {
            updateUserState && updateUserState(response.data.user);
            toast.success(t('GooglePass'), ToastConfig);
            switch (response.data.user.role) {
            case 'player':
              navigate('/portal');
              break;
            case 'admin':
              navigate('/admin/events');
              break;
            default:
              toast.info('invalid user', ToastConfig);
              navigate('/login');
            }
          }
          else toast.error(`${response.data.message}`, ToastConfig);
        })
        // Move to 500 server error
        .catch(() => toast.error('Application Error', ToastConfig));
  };

  const validateInputFields = () => {
    // Email validation
    let isValidData = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userFields.email)) {
      setIsEmailError(true);
      setEmailErrors([t('validationEmail')]);
      isValidData = false;
    }

    const passwordRegexLowerCase = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegexLowerCase.test(userFields.password)) {
      setIsPasswordError(true);
      setPasswordErrors(
        [t('validationPassword')]
      );
      isValidData = false;
    }
    
    return isValidData;
  };

  const handleButtonClick = () => {
    gapi.auth2.getAuthInstance().signIn().then(onSuccess, onFailure);
  };

  const buttonRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: config.google.clientId,
        scope: 'profile email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <div className='content'>
          <Title level={3}>{t('secondaryTitle') }</Title>
          <Text className='info-text' style={{ fontSize: '1.1rem'}}>{t('bookingTitle') }</Text>
          <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem'}}>
            <FormGroupComponent
              className='label-animation'
              hasError={isEmailError}
              errors={emailErrors}
            >
              <InputFieldComponent
                placeholder={t('EmailPlaceholder') }
                name='email'
                type='email'
                value={userFields.email}
                onChange={handleInputField}
                required
              />
            </FormGroupComponent>
            <div>
              <FormGroupComponent
                className='label-animation'
                hasError={isPasswordError}
                errors={passwordErrors}
              >
                <InputFieldComponent
                  placeholder={t('PasswordPlaceholder') }
                  name='password'
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={userFields.password}
                  onChange={handleInputField}
                  required
                />
                {
                  isPasswordVisible ? (
                    <AiFillEyeInvisible className='icon' onClick={() =>setIsPasswordVisible(false)} fontSize={20}/>
                  ): (
                    <AiFillEye className='icon' onClick={() =>setIsPasswordVisible(true)} fontSize={20}/>
                  )
                }
              </FormGroupComponent>
              {/* <p style={{ textAlign: 'right', marginTop: '2rem' }}>
                <Link
                  to="/forgot-password"
                  style={{ fontSize: '1.25rem', fontWeight: 400, textDecoration: 'none', color: 'black', padding: 0, border: 'none' }}
                >
                  {t('forgotPassword') }
                </Link>
              </p> */}
            </div>
            <FormGroupComponent className='auth-btn-group'>
              <Button className='btn btn-primary' onClick={handleLogin}>{t('continueEmail') }</Button>
              <Button
                ref={buttonRef}
                onClick={handleButtonClick}
                className="google-btn btn"
              >
                <FcGoogle fontSize={20} />
                {t('continueGoogle') }
              </Button>
            </FormGroupComponent>
          </form>
          <p style={{ textAlign: 'center', fontSize: '1.3rem' }}>
            {t('dontHaveAccount') }
            {' '}
            <Link
              to={'/register'}
              style={{ fontWeight: 600, textDecoration: 'none', color: 'black' }}
            >
              {t('register')}
            </Link>
          </p>
        </div>
      </Container>
    </motion.div>
  );
};

export default AccountLogin;
