import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../context/StateContext';
import { PortalContent, PortalSection } from './style';

const PortalPage = () => {
  const { user } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.phoneNumber || !user?.teams) {
      navigate('/onboarding'); 
    }
  }, []);
  
  return (
    <PortalSection>
      <PortalContent>
        <Outlet />
      </PortalContent>
    </PortalSection>
  );
};

export default PortalPage;