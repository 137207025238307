import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';

export const OnBoarding = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 3rem;

    .info-onboarding-title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #191919;
        margin-bottom: 3rem;
        margin-top: 3rem;
        transform: capitalize;
        padding: 0rem;
    }
    .info-onboarding-text {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #191919;
        transform: capitalize;
        padding: 0rem;
    }

    .next-btn {
        background: #071348;
        border: 1px solid #071348;
        border-radius: 4px;
        color: var(--color-white);
        width: fit-content;
        display: flex;
        align-items: center; 
        justify-content: center; 
        gap: .5rem;
        padding: 1.75rem 2rem;
        margin-bottom: 2rem;
    }

    @media screen and (min-width: ${breakpoints.tablet}) {

    }

    @media screen and (min-width: ${breakpoints.desktop}) {

    }
`;

export const OnBoardingContent = styled.div`
    flex-basis: 90%;
    background: var(--color-white);
    padding: 5rem 4rem;
    border-radius: .6rem;
    
    @media screen and (min-width: ${breakpoints.tablet}) {
        flex-basis: 40%;
        padding: 5rem 10rem;
        overflow-y: scroll;
        max-height: 80vh;
    }
`;

