import React from 'react';
import './spinner.style.css';

const Spinner = () => {
  return (
    <div className='flex w-full p-10 items-center justify-center'>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

export default Spinner;