import Title from 'antd/es/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
const Profile = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Title level={4}>{t('account')}</Title>
  );
};

export default Profile;