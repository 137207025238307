import styled from 'styled-components';

export const FooterSection = styled.div`
    .booking-btn-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .booking-btn-box .book-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2rem 4rem !important;
        font-size: 1.4rem;
        background: var(--color-background-secondary);
        color: var(--color-white);
    }
`;

