import React, { useEffect, useState } from 'react';
import { Container } from './style';
import { activatePlayerHandler, getTeamByIdHandler, leaveTeamHandler, removePlayerHandler } from '../../api/teamApi';
import { useStateContext } from '../../context/StateContext';
import Title from 'antd/es/typography/Title';
import { Collapse, Tag, Modal, Tooltip, Button, Empty } from 'antd';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const TeamDetails = () => {
  const { t } = useTranslation('createTeam');
  const { user } = useStateContext();
  const [teams, setTeams] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState<any>(null);
  const [currentTeam, setCurrentTeam] = useState<any>(null);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamPromises = (user?.teams as unknown as any)?.map((teamId: string) => getTeamByIdHandler({ id: teamId }));
        const teamData = await Promise.all(teamPromises);
        setTeams(teamData);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchTeams();
  }, []);

  const handleOpenPlayerInfo = (id: string, lead: string, player: any, teamId: any) => {
    if (id === lead){
      setCurrentPlayer(player);
      setCurrentTeam(teamId);
      setModalVisible(true);
    }
  };

  const renderPlayerDetails = (player: any, lead: string, teamId: string) => (
    <Tooltip title={player === lead && 'Click to open player menu'}>
      <tr className="border-t border-gray-200 flex justify-start cursor-pointer" style={{ lineHeight: '2.5' }}
        onClick={() => { handleOpenPlayerInfo(user?._id as string, lead, player, teamId); }}
      >
        <td className='basis-4/6 flex justify-start'>{player.lastName} { player.firstName}</td>
        <td className=''>
          <Tag
            color={player.status === 'ACTIVE' ? 'green' : 'orange'}
          >
            {player.status}
          </Tag>
        </td>
        <td>
          {
            player.id === lead && (<Tag
              color={'green'}
            >
              LEAD
            </Tag>)
          }
        </td>
      </tr>
    </Tooltip>
    
  );

  const handleModalOk = () => {
    // Perform the action (approve or remove) based on the currentPlayer.status
    setModalVisible(false);
  };

  const handleApprove = (teamId: any) => {
    const promise = activatePlayerHandler(currentTeam, currentPlayer.id as string)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Approved');
          window.location.reload();
        } else {
          toast.error('Request failed');
        }
      });
    
    toast.promise(promise, {
      success: '',
      error: 'Request failed',
      loading: 'Waiting for a response'
    }, { duration: 10000});

  };

  const handleRemove = (teamId: any) => {
    const promise = removePlayerHandler(currentTeam, currentPlayer.id as string)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Removed');
          window.location.reload();
        } else {
          toast.error('Request failed');
        }
      });
    
    toast.promise(promise, {
      success: '',
      error: 'Request failed',
      loading: 'Waiting for a response'
    }, { duration: 10000});
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleLeaveTeam = (id: any) => {
    leaveTeamHandler({ teamId: id })
      .then(async (response) => {
        if (response.status === 200) {
          toast.success('Player left');
          window.location.reload(); 
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Title level={4} style={{}}>{t('allteams')}</Title>
      <div className="box">
        {teams.map((team: any) => (
          <React.Fragment key={team.data.data._id}>
            <Collapse>
              <Panel header={<h2 className='font-medium capitalize'>{team.data.data.name}</h2>} key={team.data.data._id}>
                <table className="table-auto w-full">
                  <thead>
                    <tr className='flex flex-row justify-start items-start'>
                      <th className='basis-4/6 flex justify-start'>{t('names')}</th>
                      <th className='flex justify-start'>{t('status')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {team?.data?.data?.members?.map((player: any) => (
                      <React.Fragment key={player._id}>
                        {renderPlayerDetails(player, team?.data?.data?.lead, team?.data?.data?._id)}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                <div className="mt-4 flex items-center justify-end">
                  <span className='bg-white text-black hover:bg-white hover:text-black cursor-pointer p-4' onClick={() => handleLeaveTeam(team?.data?.data?._id)}>
                    {t('leaveT')}
                  </span>
                </div>
              </Panel>
            </Collapse>
          </React.Fragment>
        ))}
        <Modal
          title="Player Information"
          open={modalVisible}
          footer={
            <div className='flex gap-4 mt-5'>
              {currentPlayer?.status === 'INACTIVE' && <Button className='custom-btn' onClick={handleApprove}>{t('approve')}</Button>}
              <Button className='custom-btn-ghost' onClick={handleRemove}>{t('remove')}</Button>
            </div>
          }
          onCancel={handleModalCancel}
        >
          <p>First Name: {currentPlayer?.firstName}</p>
          <p>Last Name: {currentPlayer?.lastName}</p>
          <p>Email: {currentPlayer?.email}</p>
          <p>
            {currentPlayer?.status === 'INACTIVE'
              ? 'Do you want to approve this player?'
              : 'Do you want to remove this player?'}
          </p>
        </Modal>
        {
          isEmpty && <Empty className='italic' />
        }
      </div>
    </Container>
  );
};

export default TeamDetails;
