import { ToastOptions } from 'react-toastify';

export default {
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_KEY,
    secretKey: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  },
};

export const ToastConfig: ToastOptions = {
  position: 'bottom-right',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
};