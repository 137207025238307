import React from 'react';
import { Images } from '../../assets';
import { HomePage, Header } from './style';
import Title from 'antd/es/typography/Title';
import { Button } from 'antd';
import { BsCalendar2Minus } from 'react-icons/bs';
import { MdArrowForwardIos } from 'react-icons/md';
import { GiConvergenceTarget } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation('home'); // Use the "home" namespace
  return (
    <HomePage>
      <Header>
        <div className='content-box'>
          <Title level={5}><GiConvergenceTarget color='black' /> <span>{t('secondaryTitle') }</span></Title>  
          <Title level={1}>{t('welcomeMessage')} Canal <span className='soccer'>S⚽️ccer</span></Title> 
          <p className='content-text'>
            {t('moreDetails')}
          </p>  
          <div>
            <Link style={{ textDecoration: 'none' }} to={'/login'}>
              <Button
                style={{
                  padding: '2.5rem 4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '1.5rem',
                  background: 'var(--color-blue)',
                  color: 'white'
                }}
              >
                <BsCalendar2Minus />
                <span>{t('buttonText')}</span>
              </Button>
            </Link>

          </div>        
        </div>
        <div className='image-box'>
          <img src={Images.homeIllustration} alt='home-illustration' />
        </div>
      </Header>
    </HomePage>
  );
};

export default Home;