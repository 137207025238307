import React, { useState } from 'react';
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import Form from '../../components/Form/Form';
import { updatePhoneNumber } from '../../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../context/StateContext';

// eslint-disable-next-line @typescript-eslint/ban-types
const AddPhoneNumber = () => {
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState<CountryPhoneInputValue>({ short: 'RW' });

  const savePhoneNumber = () => {
    const userPhoneNumber = `+${phoneNumber.code}${phoneNumber.phone}`;
    updatePhoneNumber({ phoneNumber: userPhoneNumber })
      .then((response) => {
        if (response.status === 200) {
          updateUserState && updateUserState(response.data.user);
          toast.success(t('successMessage'), ToastConfig); 
          navigate('/onboarding/join-team');
        }
        else toast.error(t('successMessage'), ToastConfig);
      })
      .catch(() => toast.error('Application Error', ToastConfig));
  }; 
  return (
    <div>
      <div style={{ padding: '2rem 0' }}>
        <Title level={5}><span>{t('personalDetails')}</span></Title>
        <p className='info-onboarding-text'>
          {t('message')}
        </p>
        <Form>
          {user?.phoneNumber && <p className='info-onboarding-title'>{t('currentNumber')}: {user?.phoneNumber}</p>}
          <div className='form-group'>
            <ConfigProvider locale={en}>
              <CountryPhoneInput
                inline
                placeholder='788784739'
                style={{
                  paddingLeft: '.5rem'
                }}
                className='input-field'
                value={phoneNumber}
                onChange={(v) => {
                  setPhoneNumber(v);
                }}
                selectProps={{ style: { height: 24 } }}
              />
            </ConfigProvider>
          </div>
        </Form>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
          <Button
            className='custom-btn'
            onClick={() => savePhoneNumber()}
          >
            <span>{!user?.phoneNumber ? 'Save Number' : 'Updated Number'}</span>
          </Button>
        </div>
        {
          user?.phoneNumber && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
            <Button
              className='custom-btn-ghost'
              onClick={() => navigate('/onboarding/join-team')}
            >
              <span className='capitalize'>{t('next')}</span>
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default AddPhoneNumber;