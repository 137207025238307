import React from 'react';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { AiOutlineHome, AiOutlineMenu } from 'react-icons/ai';
import { Images } from '../assets';
import { Header, MenuMobileStyled, UserProfile } from './styles';
import { useNavbarContext } from '../context/NavigationContext';
import NavbarDrawer from '../components/Menu/Drawer';
import { Link, useNavigate } from 'react-router-dom';
import { IoCalendarOutline } from 'react-icons/io5';
import { MdOutlineCalendarToday, MdOutlinePayment } from 'react-icons/md';
import { RiTeamLine } from 'react-icons/ri';
import LocalesComponent from '../components/LocalesComponent/LocalesComponent';
import { useStateContext } from '../context/StateContext';
import { TbLogout } from 'react-icons/tb';
import { logout } from '../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../config/config';

const MenuList = [
  {
    icon: <AiOutlineHome />,
    label: 'Portal',
    path: '/portal'
  },
  {
    icon: <MdOutlineCalendarToday />,
    label: 'Book Session',
    path: '/portal/book-session'
  },
  {
    icon: <IoCalendarOutline />,
    label: 'Upcoming events',
    path: '/portal/upcoming-events'
  },
  {
    icon: <RiTeamLine />,
    label: 'Team Details',
    path: '/portal/team-details'
  },
  {
    icon: <MdOutlinePayment />,
    label: 'Payment',
    path: '/portal/payment'
  },
];

const MenuDesktop = () => {
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button onClick={handleLogout} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)'}}>
          <TbLogout /><span>Logout</span>
        </Button>
      ),
    }
  ];

  return (
    <div className="menu desktop">
      {MenuList.map((item) => (
        <Link
          className={
            `nav-item ${location.pathname === item.path ? 'active' : ''} 
            ${item.label === 'Team' && location.pathname === '/onboarding/create-team' ? 'active' : ''}`
          }
          to={item.path}
          key={item.path}
        >
          <span>{item.label}</span>
        </Link>))}
      <LocalesComponent />
      <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
        <Dropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            {user?.firstName?.slice(0, 2).toLocaleUpperCase()}
          </a>
        </Dropdown>
      </Avatar>
    </div>
  );
};

const MenuMobile = () => {
  const { toggleNavbar } = useNavbarContext();
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };
  return (
    <div className="menu mobile">
      <MenuMobileStyled>
        {MenuList.map(item => (
          <Link className='nav-item' onClick={toggleNavbar} key={item.path} to={item.path}>
            {item.icon}
            <span>{item.label}</span>
          </Link>
        ))}
      </MenuMobileStyled>
      {
        user && user?.email && (
          <UserProfile>
            <div className='user-details' onClick={() => navigate('/portal')}>
              <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
                { user?.firstName?.slice(0, 2).toLocaleUpperCase() }
              </Avatar>
              <div className='user-name'>
                <p>{`${user?.firstName?.toLocaleUpperCase()} ${user?.lastName?.toLocaleUpperCase()}`}</p>
                <p style={{ fontWeight: 100, fontSize: '.9rem', wordWrap: 'break-word'}}>{ user.email }</p>
              </div>
            </div>
            <div className='user-actions'>
              <Button className='custom-btn-borderless' onClick={handleLogout}>
                <TbLogout /><span>Logout</span>
              </Button>
            </div>
          </UserProfile>
        )
      }
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PortalLayout = ({ children }: { children: any }) => {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '100vw' }}>
      <NavbarDrawer isDefault={false}>
        <MenuMobile />
      </NavbarDrawer>
      <Header style={{ background: 'var(--color-blue)'}}>
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Link to={'/'}>
            <img className="logo" src={Images.logo2Svg} alt="logo" style={{ minHeight: '4rem' }}/>
          </Link>
        </div>
        {!isNavbarOpen &&
          <div className='nav-menu-mobile'>
            <LocalesComponent style={{ color: 'white' }}/>
            <AiOutlineMenu onClick={toggleNavbar} className="menu-icon" style={{ color: 'white'}} />
          </div>
        }
        <MenuDesktop />
      </Header>
      <div
        style={{
          background: `url(${Images.footballField})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          filter: 'blur(7px)'
        }}
      >
      </div>
      <main>
        {children}
      </main>
    </div>
  );
};

export default PortalLayout;
