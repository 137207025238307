import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr', 'rw'],
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'translation', // Add this line

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json', // Update this line
    },

    react: {
      useSuspense: false,
    },
  }).then(() => console.log('i18next initialized'))
  .catch((error) => console.error('i18next failed to initialize:', error));


export default i18n;
