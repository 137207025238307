import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';

export const HomePage = styled.div`
    padding: 4rem;

    @media screen and (min-width: ${breakpoints.tablet}) {
        padding: 4rem 0rem 4rem 6rem;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
        padding: 4rem 0rem 4rem 10rem;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-box > *:not(:last-child) {
            margin-bottom: 3rem;
        }

        .content-box h1 {
            font-weight: bold;
            font-size: 5rem;
            margin: 0;
            font-family: 'Gochi Hand', cursive;
        }

        .content-box h5 {
            font-weight: bold;
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: flex-start;
        }

        .content-text {
            font-weight: 400;
            font-size: 1.6rem;
            width: 100%;
            line-height: 2;
        }

        .content-box h1 .soccer {
            color: var(--color-text-in-secondary);
            white-space: nowrap;
        }


    .image-box {
        display: none;
    }
    
    @media screen and (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
        max-height: 80vh;
        overflow: hidden;

        .content-box {
            flex-basis: 60%;
        }

        .content-box h1 {
            font-size: 7rem;
        }

        .content-text {
            width: 80%;
        }
        .image-box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 40%;
        }

        .image-box img {
            max-height: 75rem;
        }   
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
        .content-box h1 {
            font-size: 10rem;
        }
    }
`;
