import React, { useRef } from 'react';
import { InputField } from './style';
import { toCapitalize } from '../../utils/text';

interface InputFieldProps {
    required: boolean;
    placeholder: string;
    name: string;
    type: 'number' | 'text' | 'email' | 'password';
    value?: string | number;
    // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: Function;
}

const InputFieldComponent = ({
  required,
  placeholder,
  type,
  onChange,
  name,
}: InputFieldProps
) => {
  const inputRef = useRef<HTMLInputElement>();

  function focusInput() {
    (inputRef?.current as HTMLInputElement).focus();
  }

  return (
    <>
      <div className='label-animation'>
        <InputField
          ref={el => (inputRef.current = el as HTMLInputElement)}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e)}
          required={required}
        />
        <label onMouseOver={focusInput} onClick={focusInput}>{toCapitalize(placeholder)}</label>
      </div>
    </>
  );
};

export default InputFieldComponent;