// userAPI.js
import { userType } from '../interface/userInterface';
import api from './../services/axios';

export const getUser = async (userId: string) => {
  try {
    const response = await api.get(`/user/${userId}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.get('/user/auth/logout');
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isAuthenticated = async () => {
  try {
    const response = await api.get('/user/auth/user/verify-auth');
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const login = async (user: { email: string, password: string }) => {
  try {
    const response = await api.post('/user/auth/local/login', user);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const signup = async (user: userType) => {
  try {
    const response = await api.post('/user/auth/local/register', user);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const signupAdmin = async (user: userType) => {
  try {
    const response = await api.post('/user/auth/local/register/admin', user);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const continueWithGoogle = async (user: { token: string }) => {
  try {
    const response = await api.post('/user/auth/google', user);
    return response;  
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verifyUserAccount = async (data: {otp: string}) => {
  try {
    const response = await api.patch('/user/auth/verify', data);
    return response;  
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resendUserOTP = async () => {
  try {
    const response = await api.get('/user/auth/verify/resend-otp');
    return response;  
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const forgotPassword = async (user: {email: string}) => {
  try {
    const response = await api.post('/user/auth/forgot-password', user);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resetPassword = async (user: { password: string }, query: { userId: string, email: string }) => {
  try {
    const response = await api.patch('/user/auth/forgot-password/reset', user, { params: query });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updatePhoneNumber = async (user: { phoneNumber: string }) => {
  try {
    const response = await api.patch('/user/auth/update-phone-number', user);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const userTeams = async () => {
  try {
    const response = await api.get('/user/teams');
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
