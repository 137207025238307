/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { verifyQrCodeHandler } from '../../api/eventApi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import LoadingScreen from '../../layouts/LoadingScreen';

function formattedDateTime(eventTime: string) {
  if (eventTime) {
    const [date, time] = eventTime.split('T');
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const [hour, minute] = time.slice(0, -5).split(':');
    const formattedTime = `${hour}:${minute}`;

    return { formattedTime, formattedDate}; 
  }
}

const ConfirmQrCode = () => {
  const [state, setState] = useState({
    team: {},
    slot: {},
    isValid: false,
    user: {}
  });
  const [isLoading, setIsLoading] = useState(true);
  const { qrcode, teamId, slotId, userId } = useParams();

  const fetchData = useCallback(async () => {
    if (!!qrcode && !!teamId && !!slotId && !!userId)
      try {
        verifyQrCodeHandler(qrcode as string, teamId as string, slotId as string, userId as string)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              const { data } = response;
              setState({ team: data.team, slot: data.slot, user: data.user, isValid: data.isValid });
            } 
            setIsLoading(false);
          });
      } catch (err){
        setIsLoading(false);
      }
  }, [qrcode, teamId, slotId, userId]);

  useEffect(() => {
    fetchData();
    return () => {
      setState({
        team: {},
        slot: {},
        isValid: false,
        user: {}
      });
    };
  }, []);
    
  if (isLoading) return <LoadingScreen />;

  return (
    <div>
      <div className='flex w-full items-center justify-center p-10'>
        {
          state.isValid ? <AiFillCheckCircle className='text-green-500' fontSize={100} /> : <ImCross className='text-red-500' fontSize={100} />
        }
      </div>
      {
        state.isValid && (      <div className='text-3xl italic'>
          <h2 className='font-bold'>Event</h2>
          <div className='flex flex-col gap-10 mt-10'>
            <p className=''>Team Name: <span className='font-bold'>{(state.team as any)?.name}</span></p>
            <p className=''>Day: <span className='font-bold'>{formattedDateTime((state.slot as any)?.startTime)?.formattedDate}</span></p>
            <p className=''>Time: <span className='font-bold'>{formattedDateTime((state.slot as any)?.startTime)?.formattedTime}</span></p>
          </div>
        </div>)
      }
    </div>
  );
};

export default React.memo(() => <ConfirmQrCode />);
