import axios, { AxiosInstance } from 'axios';

// const baseURL = 'http://18.216.226.21/api/v1/';
const baseURL = process.env.REACT_APP_API;

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return status < 500;
  }
});

// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response && error.response.status === 500) {
//       console.error('An error occurred:', error);
//       toast.error('An Error has occured, please try again later', {
//         draggable: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         position: 'bottom-right',
//         autoClose: 4000,
//         hideProgressBar: true,
//       });
//       // Handle the error as needed, such as showing a message to the user or logging the error
//     }
//     return Promise.resolve(error);
//   }
// );


export default axiosInstance;
