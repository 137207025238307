import styled from 'styled-components';
import { breakpoints } from '../breakpoints';

export const MenuMobileStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: fle-start;
    justify-content: center;
    gap: 2rem;
    font-weight: 500;

    a {
      text-decoration: none !important;
      color: black;
    }

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.75rem;
      font-size: 1.4rem;
      font-family: 'Montserrat', sans-serif !important;
    }
`;

export const NavItem = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  transition: all .3s ease;
  border-radius: 10px;
  background: transparent;

  &:hover {
      background: purple;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-background-white);
  padding: 3rem;

  .logo {
    width: 60%;
  }

  .menu-icon {
    color: var(--color-background-secondary);
    font-size: 2.5rem;
  }

  .menu.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .menu.desktop {
    display: none;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-background-secondary);
    color: var(--color-white);
    padding: 2rem 4rem;
    font-size: 1.4rem;
  }

  .btn-lang {
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    font-weight: 700;
  }

  .nav-item {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid transparent;
      padding-bottom: .5rem;
      transition: .3s all ease;
  }

  .nav-menu-mobile {
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    gap: 1rem;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 1rem 8rem;
    
    &.default {
      padding: 4rem 8rem;
    }

    .menu-icon {
      display: none;
    }

    .menu.desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }

    .nav-menu-mobile {
      display: none;
    }

    &.default .menu.desktop a{
      text-decoration: none;
      color: var(--color-text-in-tertiary);
      font-weight: 500;
      font-size: 1.3rem;
      cursor: pointer;
    }

    & .menu.desktop a, 
    & .menu.desktop button {
      text-decoration: none;
      color: var(--color-text-in-primary);
      font-weight: 500;
      font-size: 1.3rem;
      cursor: pointer;
    }

    &.default .menu.desktop button {
      text-decoration: none;
      color: var(--color-text-in-tertiary);
      font-weight: 500;
      font-size: 1.3rem;
      cursor: pointer;
    }

    .menu.desktop button {
      font-size: 1.2rem;
      border: none;
      border-bottom: .5px solid transparent;
      border-radius: 0;
      padding-bottom: .3rem;
      transition: .3s all ease;
    }

    .nav-item.active,
    .nav-item:hover {
      border-bottom: 1px solid var(--color-text-in-primary);
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-grow: 1;
  gap: 5rem;
`;

export const SideBar = styled.div`
  flex-basis: 20%;
  background: var(--color-white);

  .sidebar-list {
    width: 85%;
  }

  .sidebar-list a {
    font-size: 1.35rem;
    color: var(--color-text-in-black);
    text-decoration: none;
    font-weight: 700;
    background: var(--color-background-secondary);
    color: white;
    padding: 2rem 3rem;
    border-radius: 3rem;
    width: 100%;
  }

  .sidebar-list a.active {
    
  }

  .sidebar-list a svg {
    color: black;
    color: white;
  }
`;

export const MainContent = styled.div`
  flex-grow: 1;
`;


export const UserProfile = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .user-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.5rem;
  }

  .user-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: .5rem;
      font-weight: 700;
      font-size: 1.1rem;
  }

  .user-actions {
    margin-top: 2rem;
  }

  .user-actions > * {
      font-weight: 500 !important;
      color: black;
  }
`;