import styled from 'styled-components';

export const Container = styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    gap: 4rem;
    padding: 2rem 0 4rem;

    .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        gap: 2rem;
    }

    .book-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2rem 4rem !important;
        font-size: 1.4rem;
        background: var(--color-background-secondary);
        color: var(--color-white); 
    }

    .info-text {
        font-family: 'Raleway';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A3A3A3;
    }
`;
