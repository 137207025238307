import React, { useEffect, useState } from 'react';
import { OnBoarding, OnBoardingContent } from './styles';
import {  Button, Steps } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';

const OnBoardingPage = () => {
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState<number>(1);
  const { user } = useStateContext();

  useEffect(() => {
    if (!user?.phoneNumber || location.pathname === '/onboarding/background') {
      if (location.pathname !== '/onboarding/background') {
        navigate('/onboarding/background');
      } else if (step !== 1) {
        setStep(1); 
      }
    }
    else if (!user?.teams || location.pathname === '/onboarding/join-team' || location.pathname === '/onboarding/create-team') {
      if (location.pathname !== '/onboarding/join-team' && location.pathname !== '/onboarding/create-team') {
        navigate('/onboarding/join-team');
      } else if (step !== 2) {
        setStep(2); 
      }
    } else {
      setStep(3); 
    }
  }, []);

  return (
    <div>
      <OnBoarding>
        <OnBoardingContent>
          <Title level={4} style={{ marginBottom: '5rem'}}>{t('onboarding')}</Title>
          <Steps
            responsive={false}
            direction="horizontal"
            current={step - 1}
            percent={0}
            items={[{}, {}, {}]}
          />
          <div style={{ marginBottom: '5rem'}} />
          <Outlet />
          {step === 3 && (
            <div>
              {/* <Title level={5}>{t('onboarding')}</Title> */}
              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '2rem',
                marginBottom: '4rem',
                marginTop: '2rem',
              }}>
                <p style={{ fontSize: '1.3rem', fontWeight: 400 }}>📞 {t('phonenumber')}: <span style={{ fontWeight: 700}}>{user?.phoneNumber}</span></p>
                <p style={{ fontSize: '1.3rem', fontWeight: 400  }}>✅ {t('joinedteam')}: <span style={{ fontWeight: 700}}>{user?.teams?.length}</span></p>
              </div>
              <Button className='custom-btn' onClick={() => navigate('/portal')}>{t('book')}</Button>
            </div>
          )}
        </OnBoardingContent>
      </OnBoarding>
    </div>
  );
};

export default OnBoardingPage;