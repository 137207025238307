import React, { useEffect, useRef, useState } from 'react';
import Typography from 'antd/es/typography';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import { Container, SingleInputField } from './style';
import { motion } from 'framer-motion';
import { Button } from 'antd';
import { resendUserOTP, verifyUserAccount } from '../../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const AccountVerification = () => {
  const { t } = useTranslation('accountVerification');
  const navigate = useNavigate();
  const numberOfInputs = 6;
  const [otp, setOtp] = useState(new Array(numberOfInputs).fill(''));
  const inputRefs = useRef<HTMLInputElement[] | null[]>([]);
    
  // Set focus on the first input when the component mounts
  useEffect(() => {
    (inputRefs.current[0] as HTMLInputElement)?.focus();
  }, []);

  // Update the OTP state as the user types in each input
  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Move focus to the next input once the user has entered a digit
    if (value && index < numberOfInputs - 1) {
      (inputRefs.current[index + 1] as HTMLInputElement)?.focus();
    }
  };
    
  // Delete the contents of a field when backspace is pressed and the field is empty
  const handleInputKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index]) {
      setOtp(prevOtp => {
        const newOtp = [...prevOtp];
        newOtp[index - 1] = '';
        return newOtp;
      });

      // Move focus to the previous input field after deleting the contents
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
    
  // Fill in the OTP fields when the OTP code is pasted into the input field
  const handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const otpArray = clipboardData.slice(0, numberOfInputs).split('');
    setOtp(otpArray);
  };
    
    
  // Make the API request to verify the OTP
  const verifyOtp = async () => {
    try {
      otp.forEach((code: string) => {
        if (code === '') throw new Error('Invalid OTP');
      });
      const OTP = otp.join('');
      // console.log('calling');
      verifyUserAccount({ otp: OTP })
        .then((response) => {
          console.log(response);
          if (response.status === 200) navigate('/onboarding/add-phone-number');
          else toast.error(t('OTPfail'), ToastConfig);
        });
    } catch (error) {
      toast.error(t('OTPverifying'), ToastConfig);
    }
  };
    
  const resendOtp = async () => {
    try {
      resendUserOTP()
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('OTPsuccesfull'), ToastConfig);
          } else {
            toast.error('Request failed', ToastConfig);
          }
        });
    } catch (error) {
      console.error(t('OTPverifying'), error);
    }
  };
    
    


  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <div className='content'>
          <Title level={3}>{t('verificationMessage') }</Title>
          <Text className='info-text' style={{ fontSize: '1.1rem'}}>{t('OTPconfirm')}</Text>
          <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem' }}>
            <FormGroupComponent>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                {otp.map((digit, index) => (
                  <SingleInputField
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onKeyDown={event => handleInputKeyDown(index, event)}
                    onChange={event => handleInputChange(index, event)}
                    onPaste={handleInputPaste}
                    ref={el => (inputRefs.current[index] = el as HTMLInputElement)}
                  />
                ))}
              </div>
            </FormGroupComponent>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '2rem'}}>
              <Button onClick={resendOtp}>{t('resendOTP') }</Button>
              <Button onClick={verifyOtp}>{t('verifyOTP') }</Button>
            </div>       
          </form>
        </div>
      </Container>
    </motion.div>
  );
};

export default AccountVerification;
