import React from 'react';
import LanguageProvider from './context/LanguageContext';
import StateProvider from './context/StateContext';
import NavbarProvider from './context/NavigationContext';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <LanguageProvider>
      <StateProvider>
        <NavbarProvider>
          <BrowserRouter>
            <Router />
            <Toaster/>
          </BrowserRouter>
        </NavbarProvider>
      </StateProvider>
    </LanguageProvider>
  );
};

export default React.memo(() => <App />);