import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';

export const Container = styled.div`
  padding: 4rem 4rem 2rem;


  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 4rem 0 4rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .content {
      width: 60%;
      border: 1px solid #D3D3D3;
      border-radius: 1rem;
      padding: 8rem;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    .content {
      width: 40%;
      padding: 6rem 10rem;
    }  
  }
`;
