import React, { useState } from 'react';
import { Button, Collapse, Empty, Pagination, Select, Tag } from 'antd';
import { splitTimestamp } from '../../utils/time';
import { useStateContext } from '../../context/StateContext';


const { Panel } = Collapse;

const ScrollableTable = ({ data, onDelete, onPay }: { data: any[], onDelete: any, onPay: any }) => {
  const [current, setCurrent] = useState(1);
  // const [selectedMonth, setSelectedMonth] = useState('');
  const pageSize = 5;

  // const monthOptions = [
  //   { value: '', label: 'All Months' },
  //   { value: '01', label: 'January' },
  //   { value: '02', label: 'February' },
  //   { value: '03', label: 'March' },
  //   { value: '04', label: 'April' },
  //   { value: '05', label: 'May' },
  //   { value: '06', label: 'June' },
  //   { value: '07', label: 'July' },
  //   { value: '08', label: 'August' },
  //   { value: '09', label: 'September' },
  //   { value: '10', label: 'October' },
  //   { value: '11', label: 'November' },
  //   { value: '12', label: 'December' },
  // ];

  // const handleMonthChange = (value: string) => {
  //   setSelectedMonth(value);
  // };

  const renderPanelContent = (item: any) => {
    const { user } = useStateContext();
    const statusTagColor = item.paymentInfo.toUpperCase() === 'PENDING' ? 'orange' : 'green';

    const showCancelButton = () => {
      if (user?._id === item?.teamId?.lead) {
        if (item.isPaid)
          return false;
        else
          return true;   
      }
      if (item?.leftTeamId?.lead === user?._id) {
        if (item.isPaidLeftTeam)
          return false;
        else
          return true;   
      }
    };
      
    return (
      <div className='mb-2' style={{ marginBottom: '.2rem'}}>
        <div className="space-y-3">
          <div>Date: {splitTimestamp(item.startTime).day}</div>
          <div>Start Time: {splitTimestamp(item.startTime).hour}</div>
          <div>End Time: {splitTimestamp(item.endTime).hour}</div>
          <div className='mb-5'>
            {/* <Tag color={statusTagColor}>{item.paymentInfo.toUpperCase()}</Tag> */}
          </div>
          {/* {
            (showCancelButton()) && (
              <Button className='capitalize text-black'>
                cancel
              </Button>
            )
          } */}
        </div>
      </div>
    );
  };

  const sortedData = [...data].sort(
    (a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
  );
    
  const title = (item: any) => {
    if (item?.leftTeamId?.name)
      return `${item?.teamId?.name} vs ${item?.leftTeamId?.name}`;
    else
      return `${item?.teamId?.name}`;
  };

  const dataSlice = sortedData.slice((current - 1) * pageSize, current * pageSize);

  return (
    <>
      {
        dataSlice.length ? (
          <><Collapse
            className="bg-white rounded-md shadow-md mt-5 py-3 text-left"
            expandIconPosition="right"
            bordered={false}
          >
            {dataSlice.map((item) => (
              <Panel
                key={item._id}
                header={<h2 className='font-normal capitalize'>{title(item)}</h2>}
                className="text-xl"
              >
                {renderPanelContent(item)}
              </Panel>
            ))}
          </Collapse><div className='flex items-end w-full justify-end mt-2'>
            <Pagination
              current={current}
              total={sortedData.length}
              pageSize={pageSize}
              onChange={(page) => setCurrent(page)}
              className="my-5" />
          </div></>
        ) : <Empty className="italic" />
      }
    </>
  );
};

export default ScrollableTable;
