import React from 'react';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { AiOutlineMenu } from 'react-icons/ai';
import { Images } from '../assets';
import { Header, MenuMobileStyled, UserProfile } from './styles';
import { useNavbarContext } from '../context/NavigationContext';
import NavbarDrawer from '../components/Menu/Drawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocalesComponent from '../components/LocalesComponent/LocalesComponent';
import { useStateContext } from '../context/StateContext';
import { logout } from '../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../config/config';
import { TbLogout } from 'react-icons/tb';
import { MdOutlineDashboard } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';

const MenuList = [
  {
    icon: <>ℹ️</>,
    label: 'Background',
    path: '/onboarding/background'
  },
  {
    icon: <>🏟️</>,
    label: 'Team',
    path: '/onboarding/join-team'
  },
];

const MenuDesktop = () => {
  const { user, updateUserState } = useStateContext();
  const location = useLocation();
  const navigate = useNavigate();


  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button onClick={handleLogout} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)'}}>
          <TbLogout /><span>Logout</span>
        </Button>
      ),
    }
  ];

  return (
    <div className="menu desktop">
      {MenuList.map((item) => (
        <Link
          className={
            `nav-item ${location.pathname === item.path ? 'active' : ''} 
            ${item.label === 'Team' && location.pathname === '/onboarding/create-team' ? 'active' : ''}`
          }
          to={item.path}
          key={item.path}
        >
          <span>{item.label}</span>
        </Link>))}
      <LocalesComponent />
      <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
        <Dropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            { user?.firstName?.slice(0, 2).toLocaleUpperCase() }
          </a>
        </Dropdown>
      </Avatar>
    </div>
  );
};

const MenuMobile = () => {
  const { toggleNavbar } = useNavbarContext();
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };

  return (
    <div>
      <MenuMobileStyled>
        {MenuList.map(item => (
          <Link className='nav-item' onClick={toggleNavbar} key={item.path} to={item.path}>
            {item.icon}
            <span>{item.label}</span>
          </Link>
        ))}
      </MenuMobileStyled>
      {
        user && user?.email && (
          <UserProfile>
            <div className='user-details' onClick={() => navigate('/portal')}>
              <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
                { user?.firstName?.slice(0, 2).toLocaleUpperCase() }
              </Avatar>
              <div className='user-name'>
                <p>{`${user?.firstName?.toLocaleUpperCase()} ${user?.lastName?.toLocaleUpperCase()}`}</p>
                <p style={{ fontWeight: 100, fontSize: '.9rem', wordWrap: 'break-word'}}>{ user.email }</p>
              </div>
            </div>
            {
              user.role === 'player' && (
                <div className='user-actions'>
                  <Link className='custom-btn-borderless' to={'/portal'}>
                    <MdOutlineDashboard /><span>Portal</span>
                  </Link>
                  {/* <Link className='custom-btn-borderless' to={'/portal/profile'}>
                    <ImProfile /><span>Profile</span>
                  </Link> */}
                  <Button className='custom-btn-borderless' onClick={handleLogout}>
                    <TbLogout /><span>Logout</span>
                  </Button>
                </div>
              )
            }
          </UserProfile>
        )
      }
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OnboardingLayout = ({ children }: { children: any }) => {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '100vw' }}>
      <NavbarDrawer isDefault={false}>
        <MenuMobile />
      </NavbarDrawer>
      <Header style={{ background: 'var(--color-blue)'}}>
        <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <Link to={'/'}>
            <img className="logo" src={Images.logo2Svg} alt="logo" style={{ minHeight: '4rem' }}/>
          </Link>
        </div>
        {!isNavbarOpen &&
          <div className='nav-menu-mobile'>
            <LocalesComponent style={{ color: 'white' }}/>
            <AiOutlineMenu onClick={toggleNavbar} className="menu-icon" />
          </div>
        }
        <MenuDesktop />
      </Header>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div
          className='background'
          style={{
            background: `url(${Images.footballField})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: '-1',
            filter: 'blur(7px)'
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default OnboardingLayout;
