import api from './../services/axios';

export const getBillsByTeam = async(team: { teamId: string}) => {
  try {
    return await api.get(`/bill/${team.teamId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const payBill = async(bill: { billId: string}) => {
  try {
    return await api.patch(`/bill/${bill.billId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};