import styled from 'styled-components';

export const FormGroup = styled.div`
    .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    //label animation 
    &.label-animation {
        position: relative;
        left: 0;
    }

    &.label-animation input::placeholder {
        opacity: 0;
    }

    &.label-animation label {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: all .3s ease;
        font-size: 1.3rem;
    }

    &.label-animation input:not(:placeholder-shown) ~ label,
    &.label-animation input:focus ~ label {
        top: -.5rem;
        font-weight: 600;
    }

    &.auth-btn-group {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-top: 2rem;
    }

    &.auth-btn-group .btn {
        height: 3rem;
        border-radius: 4rem;
        font-size: 1.3rem;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }

    &.auth-btn-group .google-btn {
        background: transparent;
        gap: 1rem;
        color: black;
        border: 1px solid black;
    }


    label {
        font-size: 1.4rem;
        font-weight: 500;
    }
`;

export const FormGroupContainer = styled.div`

`;

export const ErrorContainer = styled.div`
        margin-top: 1.5rem;
`;