import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-itens: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  // padding: 2rem 1rem;
  width: 100%;

  .form-group {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  // remove antd border on input field
  .ant-input, .ant-input-group-addon {
    border: none;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #828282;
  }

  .input-icon-left {
    left: 1rem;
  }

  .input-icon-right {
    right: 2.5rem;
    cursor: pointer;
  }

  .input-field {
    width: 100%;
    height: 5rem;
    padding: 1rem 8px;
    padding-left: 6rem;

    background: #ffffff;

    border: 1px solid #f2f2f2;
    border-radius: 4px;
  }

  .select-field {
    border-radius: 4px;
  }

  .select-field .ant-select-selection-item {
    padding-left: 5rem !important;
  }

  .select-field .ant-select-selector {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;

  }

  .select-icon {
    position: absolute;
    top: 50%;
    left: 2.5rem;
    font-size: 2rem;
    z-index: 5;
    color: #828282;
    transform: translateY(-50%);
  }

  .input-field::placeholder {
    color: #828282;
  }

  .input-field:focus {
    outline: none;
  }

  .submit-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 40px;

    background: #071348;
    border: 1px solid #071348;
    border-radius: 4px;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .google-btn {
    width: 100%;
    padding: 0px;
    gap: 16px;

    height: 40px;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;
    color: #191919;
  }
`;
