/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Collapse, Empty, Pagination } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { BillComponent, Container } from './style';
import { useStateContext } from '../../context/StateContext';
import { getBillsByTeam, payBill } from '../../api/billApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useTranslation } from 'react-i18next';

const BillPanelHeader = ({ amount, isPaid }: { isPaid: boolean, amount: string }) => {
  const { t } = useTranslation('payment');
  return <span style={{ fontSize: '1.25rem', lineHeight: '2rem', fontWeight: 500, color: isPaid ? 'green': 'black'}}>{t('due')}: { amount} RWF</span>;
};

const Bills = ({ teamId, show }: { show: string, teamId: string }) => {
  const { t } = useTranslation('payment');
  const [bills, setBills] = useState([]);
  const [hasStateChanged, setHasStateChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const billsPerPage = 5;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastBill = currentPage * billsPerPage;
  const indexOfFirstBill = indexOfLastBill - billsPerPage;
  const currentBills = bills.slice(indexOfFirstBill, indexOfLastBill);

  useEffect(() => {
    getBillsByTeam({ teamId })
      .then((response) => {
        if (response.status === 200) {
          setBills(response.data.data?.reverse());
        }
      });
  }, [teamId]);

  useEffect(() => {
    if (hasStateChanged) {
      getBillsByTeam({ teamId })
        .then((response) => {
          if (response.status === 200) {
            setBills(response.data.data);
          }
        });
      setHasStateChanged(false);
    } 
  }, [hasStateChanged]);

  const handlePayBill = (billId: string) => {
    payBill({ billId })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.url) {
            window.location.href = response.data.url;
            // window.open(response.data.url, '_blank');
          }
        } else {
          toast.info(response.data.message || 'Failed', ToastConfig);
        }
      });
  };

  return (
    <div>
      {
        bills.map((bill: any) => {
          if (show === 'paid' && !bill.isPaid) return;
          if (show === 'unpaid' && bill.isPaid) return;
          if (show === 'canceled' && bill.status) return;
          return (
            <BillComponent key={bill._id}>
              <Collapse collapsible='header' style={{ width: '100%'}}>
                <Collapse.Panel header={<BillPanelHeader isPaid={ bill.isPaid } amount={ bill.amount} />} key="1">
                  <p style={{ fontSize: '1.15rem', lineHeight: '2rem', fontWeight: 400 }}>{bill.description}</p>
                  {/* {
                    bill.eventId ? <Link to={`/portal/upcoming-events/${bill.eventId}`}>{t('viewevent')}</Link> : null
                  } */}
                </Collapse.Panel>
              </Collapse>
              {
                !bill.isPaid && <Button onClick={() => handlePayBill(bill._id)} className='custom-btn-green'>{t('paynow')}</Button>
              }
            </BillComponent>
          );
        })
      }
      {
        !bills.length && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
      }
    </div>
  );
};

const Payment = () => {
  const { t } = useTranslation('payment');
  const { user } = useStateContext();
  const [show, setShow] = useState('all');

  return (
    <Container>
      <Title level={5} style={{}}>{t('bills')}</Title>

      <div className='capitalize' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem', marginBottom: '2rem', marginTop: '2rem' }}>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'all' ?  700 : 500, cursor: 'pointer'}} onClick={() => setShow('all')}>{t('all')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'paid' ?  700 : 500, cursor: 'pointer'}}  onClick={() => setShow('paid')}>{t('paid')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'unpaid' ? 700 : 500, cursor: 'pointer' }} onClick={() => setShow('unpaid')}>{t('unpaid')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'canceled' ?  700 : 500, cursor: 'pointer'}} onClick={() => setShow('canceled')}>{t('cancelled')}</span>
      </div>
      {
        (user?.teams as unknown as Array<string>)?.map((teamId: string) => (<Bills show={show} key={teamId} teamId={teamId} />))
      }

    </Container>
  );
};

export default Payment;