import styled from 'styled-components';

export const InputField = styled.input`
    background: transparent;
    width: 100%;
    height: 4rem;
    border: none;
    border-bottom: .8px solid black;
    font-size: 1.6rem;
    padding: 1rem 2rem 1rem 0;

    &:focus-visible {
        outline: none;
    }
`;

