import React from 'react';
import { AiFillSetting, AiOutlineMenu } from 'react-icons/ai';
import { Images } from '../assets';
import { Header, MenuMobileStyled, UserProfile } from './styles';
import { useNavbarContext } from '../context/NavigationContext';
import NavbarDrawer from '../components/Menu/Drawer';
import { Link, useNavigate } from 'react-router-dom';
import LocalesComponent from '../components/LocalesComponent/LocalesComponent';
import { ImHome, ImProfile } from 'react-icons/im';
import { MdOutlineDashboard, MdOutlineLogin, MdOutlinePersonAddAlt1 } from 'react-icons/md';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { useStateContext } from '../context/StateContext';
import { ToastConfig } from '../config/config';
import { toast } from 'react-toastify';
import { logout } from '../api/userApi';
import { TbLogout } from 'react-icons/tb';
import { RxDashboard } from 'react-icons/rx';



const MenuDesktop = () => {
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();


  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };

  const UserPlatform = user?.role === 'player' ?
    (
      <>
        <Button onClick={() => navigate('/portal')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
          <RxDashboard /><span>Portal</span>
        </Button>
      </>
    )
    :
    (<Button onClick={() => navigate('/admin/events')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
      <RxDashboard /><span>Portal</span>
    </Button>);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: ( user?.role === 'player' && 
        <Button onClick={() => navigate('/onboarding/background')} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
          <AiFillSetting /><span>Onboarding</span>
        </Button>
      )
    },
    {
      key: '2',
      label: <>{UserPlatform}</>
    },
    {
      key: '3',
      label: (
        <Button onClick={handleLogout} ghost className='custom-btn-borderless' style={{ color: 'var(--color-text-in-tertiary)' }}>
          <TbLogout /><span>Logout</span>
        </Button>
      ),
    },
  ];
  return (
    <div className="menu desktop">
      <Link className="nav-item" to={'/'}>Home</Link>
      <Link className="nav-item" to={'/login'}>Login</Link>
      <Link className="nav-item" to={'/register'}>Register</Link>
      <LocalesComponent />
      {
        user && user.email && (
          <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
            <Dropdown menu={{ items }}>
              <a onClick={(e) => e.preventDefault()} style={{ color: 'var(--color-text-in-primary)'}}>
                {user?.firstName?.slice(0, 2).toLocaleUpperCase()}
              </a>
            </Dropdown>
          </Avatar>
        )
      }
    </div>
  );
};

const MenuMobile = () => {
  const { toggleNavbar } = useNavbarContext();
  const { user, updateUserState } = useStateContext();
  const navigate = useNavigate();


  const handleLogout = () => {
    logout()
      .then(() => {
        updateUserState && updateUserState({});
        toast.success('User loged out!', ToastConfig);
        navigate('/');
      });
  };
  return (
    <div>
      <MenuMobileStyled onClick={toggleNavbar}>
        <Link className="nav-item" to={'/'}><ImHome /><span>Home</span></Link>
        <Link className="nav-item" to={'/login'}><MdOutlineLogin /><span>Login</span></Link>
        <Link className="nav-item" to={'/register'}><MdOutlinePersonAddAlt1 /><span>Register</span></Link>
      </MenuMobileStyled>
      {
        user && user.email && (
          <UserProfile>
            <div className='user-details' onClick={() => navigate('/portal')}>
              <Avatar style={{ backgroundColor: '#DD4A48', verticalAlign: 'middle' }} size="default" gap={4}>
                { user?.firstName?.slice(0, 2).toLocaleUpperCase() }
              </Avatar>
              <div className='user-name'>
                <p>{`${user?.firstName?.toLocaleUpperCase()} ${user?.lastName?.toLocaleUpperCase()}`}</p>
                <p style={{ fontWeight: 100, fontSize: '.9rem', wordWrap: 'break-word'}}>{ user.email }</p>
              </div>
            </div>
            {
              user.role === 'admin' && (
                <div className='user-actions'>
                  <Link className='custom-btn-borderless' to={'/admin/events'}>
                    <MdOutlineDashboard /><span>Admin</span>
                  </Link>
                  <Link className='custom-btn-borderless' to={'/admin/teams'}>
                    <ImProfile /><span>Upcoming Events</span>
                  </Link>
                  <Button className='custom-btn-borderless' onClick={handleLogout}>
                    <TbLogout /><span>Logout</span>
                  </Button>
                </div>
              )
            }
            {
              user.role === 'player' && (
                <div className='user-actions'>
                  <Link className='custom-btn-borderless' to={'/portal'}>
                    <MdOutlineDashboard /><span>Portal</span>
                  </Link>
                  <Link className='custom-btn-borderless' to={'/onboarding/background'}>
                    <AiFillSetting /><span>Onboarding</span>
                  </Link>
                  {/* <Link className='custom-btn-borderless' to={'/portal/profile'}>
                    <ImProfile /><span>Profile</span>
                  </Link> */}
                  <Button className='custom-btn-borderless' onClick={handleLogout}>
                    <TbLogout /><span>Logout</span>
                  </Button>
                </div>
              )
            }
          </UserProfile>
        )
      }
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultLayout = ({ children }: { children: any }) => {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();

  return (
    <>
      <Header className='default'>
        <NavbarDrawer isDefault={false}>
          <MenuMobile />
        </NavbarDrawer>
        <div>
          <Link to={'/'}>
            <img className="logo" src={Images.logoSvg} alt="logo" />
          </Link>
        </div>
        {!isNavbarOpen &&
          <div className='nav-menu-mobile'>
            <LocalesComponent />
            <AiOutlineMenu onClick={toggleNavbar} className="menu-icon" />
          </div>
        }
        <MenuDesktop/>
      </Header>
      <main>{children}</main>
    </>
  );
};

export default DefaultLayout;
