import React, {  useState } from 'react';
import { DatePicker, TimePicker, Button, List, Typography, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs-ext';
import { EventsComponents } from './style';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { scheduleEventHandler } from '../../api/eventApi';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

interface Event {
  id?: number;
  date: dayjs.Dayjs;
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
}


const Events = () => {
  const { t } = useTranslation('events');
  const [events, setEvents] = useState<Event[]>([]);
  // const [scheduledEvents, setScheduledEvents] = useState<Event[]>([]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(null);
  const [editEvent, setEditEvent] = useState<Event | null>(null);
  const [isClashing, setIsClashing] = useState(false);
  const [isClashingText, setIsClashingText] = useState('');


  const scheduleEvents = () => {
    const day = selectedDate?.format('YYYY-MM-DD');
    scheduleEventHandler({ day: day as string, slotsData: events })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        if (response)
          toast.success(t('shedulesaved'), ToastConfig);
        else {
          toast.info(t('shedulefail'), ToastConfig);
          toast.error(response.data.message);
        }
      });
  };
  

  const createEvent = () => {
    if (!selectedDate || !selectedTime) return;

    const newEvent: Event = {
      id: events.length + 1,
      date: selectedDate,
      startTime: selectedTime,
      endTime: dayjs(selectedTime).add(2, 'hour')
    };

    setEvents([...events, newEvent]);

    const clashingEvents = events.filter((event) => {
      return (
        event.id !== newEvent.id &&
        event.date.isSame(newEvent.date, 'day') &&
        newEvent.startTime.isBefore(event.endTime) &&
        event.startTime.isBefore(newEvent.endTime)
      );
    });

    if (clashingEvents.length > 0) {
      const message = `The event you are trying to schedule clashes with existing event(s): ${clashingEvents.map((event) => `Event #${event.id}`).join(', ')}. Do you want to continue?`;
      setIsClashingText(message);
      setIsClashing(true);

      const nonClashingEvents = events.filter((event) => !clashingEvents.includes(event));
      setEvents([...nonClashingEvents, newEvent]);
    }
    // sortEventsByStartTime();
  };

  const generateDefaultPlan = () => {
    if (!selectedDate) {
      toast.info(t('selecter'), ToastConfig);
      return;
    }

    const defaultEvents: Event[] = [];

    for (let i = 8; i <= 20; i += 2) {
      defaultEvents.push({
        id: defaultEvents.length + 1,
        date: selectedDate,
        startTime: selectedDate.hour(i).minute(0),
        endTime: dayjs(selectedDate.hour(i).minute(0)).add(2, 'hour'),
      });
    }

    setEvents(defaultEvents);
  };

  const handleDelete = (eventToDelete: Event | null) => {
    if (!eventToDelete) return;

    // Remove the event to delete and any clashing events
    const filteredEvents = events.filter((event) => {
      if (event.id === eventToDelete.id) return false;
      if (event.date.isSame(eventToDelete.date, 'day')) {
        const start1 = event.startTime.valueOf();
        const end1 = event.endTime.valueOf();
        const start2 = eventToDelete.startTime.valueOf();
        const end2 = eventToDelete.endTime.valueOf();
        if ((start1 <= start2 && end1 > start2) || (start2 <= start1 && end2 > start1)) {
          return false;
        }
      }
      return true;
    });

    setEvents(filteredEvents);
    // sortEventsByStartTime();
    setEditEvent(null);
  };

  const handleSave = () => {
    if (!editEvent) return;

    const updatedEvents = events.map((event) => {
      if (event.id === editEvent.id) {
        return {
          ...editEvent,
          // endTime: dayjs(editEvent.startTime).add(2, 'hour'),
        };
      }

      return event;
    });

    const clashingEvents = updatedEvents.filter((event) => {
      return (
        event.id !== editEvent.id &&
      event.date.isSame(editEvent.date, 'day') &&
      editEvent.startTime.isBefore(event.endTime) &&
      event.startTime.isBefore(editEvent.endTime)
      );
    });

    if (clashingEvents.length > 0) {
      const message = `The event you are trying to schedule clashes with existing event(s): ${clashingEvents.map((event) => `Event #${event.id}`).join(', ')}. Do you want to continue?`;
      setIsClashingText(message);
      setIsClashing(true);

      const nonClashingEvents = updatedEvents.filter((event) => !clashingEvents.includes(event));
      setEvents(sortEventsByStartTime([...nonClashingEvents]));
    } else {
      setEvents(updatedEvents);
    }
    setEditEvent(null);
  };

  function sortEventsByStartTime(myEvents: Event[]) {
    const sortedEvents = myEvents.sort((a, b) => a.startTime.diff(b.startTime));
    sortedEvents.forEach((event, index) => {
      event.id = index + 1;
    });
    return sortedEvents;
  }


  return (
    <EventsComponents>
      <Modal
        open={isClashing}
        onOk={() => setIsClashing(false)}
        onCancel={() => setIsClashing(false)}
      >
        <div>
          <p>{isClashingText}</p>
        </div>
      </Modal>
      <Modal
        open={editEvent !== null}
        onCancel={() => setEditEvent(null)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
            <Button className='custom-btn-ghost' onClick={() => handleDelete(editEvent)}>{t('delete')}</Button>
            <Button className='custom-btn' style={{ background: 'var(--color-blue)'}} onClick={handleSave}>{t('save')}</Button>
          </div>
        }
      >
        <div>
          <div>{t('stime')}:</div>
          <TimePicker value={editEvent?.startTime} format="HH:mm" minuteStep={15} onChange={(time) => setEditEvent({...editEvent, startTime: time ?? editEvent?.startTime as Dayjs,  endTime: editEvent?.endTime as Dayjs, date: editEvent?.date as Dayjs})} />
          <div>{t('etime')}:</div>
          <TimePicker value={editEvent?.endTime} format="HH:mm" minuteStep={15} onChange={(time) =>           setEditEvent({...editEvent, startTime: editEvent?.startTime as Dayjs, endTime: time ?? editEvent?.endTime as Dayjs, date: editEvent?.date as Dayjs})} />
        </div>
      </Modal>


      <Title level={3}>{t('scheduler')}</Title>
      <div className='settings'>
        <DatePicker className='date-picker' onChange={(date) => setSelectedDate(date)} />
        <TimePicker
          className='time-picker'
          format="HH:mm"
          minuteStep={15}
          onChange={(time) => setSelectedTime(time)}
        />
        {/* <Button className='custom-btn-ghost' icon={<PlusOutlined />} onClick={createEvent}>
          {t('addevent')}
        </Button> */}
        <Button className='custom-btn-ghost' onClick={generateDefaultPlan}>{t('generateDefault')}</Button>
      </div>
      <div className='events'>
        <List
          itemLayout="horizontal"
          dataSource={events}
          renderItem={(event: Event) => (
            <div onClick={() => setEditEvent(event)}>
              <List.Item>
                <List.Item.Meta
                  title={`Event #${event.id}`}
                  description={`${event.date.format('YYYY-MM-DD')} - ${event.startTime.format('HH:mm')} : ${event.endTime.format('HH:mm')}`}
                />
              </List.Item>
            </div>
          )}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        <Button onClick={scheduleEvents} className='custom-btn' style={{ background: 'var(--color-blue)'}}>
          {t('save')}
        </Button>
      </div>
    </EventsComponents>
  );

};

export default Events;