import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import MarkDown from 'markdown-to-jsx';
import { ErrorContainer, FormGroup, FormGroupContainer } from './style';
import { toCapitalize } from '../../utils/text';

interface FormGroupProps {
    children: ReactNode;
  className?: string;
    hasError?: boolean;
  errors?: string[];
}

const FormGroupComponent = ({ children, className, hasError, errors }: FormGroupProps) => {
  return (
    <FormGroupContainer>
      <FormGroup className={className}>
        {children}
      </FormGroup>
      {
        hasError && (
          <ErrorContainer>
            {errors?.map(
              (error: string, idx: number) => (
                <motion.p
                  key={idx}
                  style={{ color: 'var(--color-text-in-secondary)', fontWeight: 500, fontSize: '1.1rem', lineHeight: '2rem' }}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <MarkDown>{toCapitalize(error)}</MarkDown>
                </motion.p>
              )
            )}
          </ErrorContainer>
        )
      }
    </FormGroupContainer>
  );
};

export default FormGroupComponent;