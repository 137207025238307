/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Tag, Button } from 'antd';
import './style.css';
import ScrollableTable from '../ScrollableTable/ScrollableTable';

const EventTable = ({ events }: { events: any }) => {

  const handleDelete = () => {
    // Implement your delete logic here
  };

  const handlePay = (_record: any) => {
    // Implement your payment logic here
  };

  return (
    <div className="mt-4 max-h-full p-0">
      <ScrollableTable data={events} onDelete={handleDelete} onPay={handlePay} />
    </div>
  );
};


export default EventTable;