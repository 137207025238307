import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';

export const Container = styled.div`
    .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        gap: 2rem;
    }

    .submit-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2rem 4rem !important;
        font-size: 1.4rem;
        background: var(--color-background-secondary);
        color: var(--color-white); 
    }
`;

export const BillComponent = styled.div`
    display: flex;
    alignItems: flex-start;
    justifyContent: flex-start;
    gap: 1.75rem;
    flex-direction: column;
    margin-bottom: 2rem;
    
    @media screen and (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {

    }
`;
