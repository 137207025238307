import React, { useState } from 'react';
import Typography from 'antd/es/typography';
import { Button } from 'antd';
import FormGroupComponent from '../../components/FormGroup/FormGroup';
import InputFieldComponent from '../../components/InputField/InputField';
import { Container } from './style';
import { motion } from 'framer-motion';
import { forgotPassword } from '../../api/userApi';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const { t } = useTranslation('forgot');
  //   const navigate = useNavigate();
  const [userFields, setUserFields] = useState<{email: string}>({
    email: ''
  });
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputField = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setIsEmailError(false);
    setEmailErrors([]);
    setUserFields({...userFields, [name]: value});
  };
    
  const handleForgotPassword = () => {
    if (validateInputFields())
      forgotPassword(userFields)
        .then((response) => {
          if (response.status === 302)
            toast.success(response.data.message, ToastConfig);
          else toast.error(`${response.data.message}`, ToastConfig);
        })
        .catch(() => toast.error('Application Error', ToastConfig));
  };

  const validateInputFields = () => {
    // Email validation
    let isValidData = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userFields.email)) {
      setIsEmailError(true);
      setEmailErrors([t('validationPassword')]);
      isValidData = false;
    }
    
    return isValidData;
  };


  return (
    <motion.div
      initial={{ y: 70, opacity: 0 }}
      animate={{ y: [70, -15, 0], opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <div className='content'>
          <Title level={3}>{t('reset')}</Title>
          <Text className='info-text' style={{ fontSize: '1.1rem'}}>{t('emailreset')}</Text>
          <form style={{ padding: '4rem 0 4rem', display: 'flex', flexDirection: 'column', gap: '5rem'}}>
            <FormGroupComponent
              className='label-animation'
              hasError={isEmailError}
              errors={emailErrors}
            >
              <InputFieldComponent
                placeholder='Email'
                name='email'
                type='email'
                value={userFields.email}
                onChange={handleInputField}
                required
              />
            </FormGroupComponent>
            <FormGroupComponent className='auth-btn-group'>
              <Button className='btn btn-primary' onClick={handleForgotPassword}>{t('reset')}</Button>
            </FormGroupComponent>
          </form>
        </div>
      </Container>
    </motion.div>
  );
};

export default ForgotPassword;
