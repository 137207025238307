import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import Form from '../../components/Form/Form';
import { IoIosPeople } from 'react-icons/io';
import { getAllTeams, joinTeamHandler } from '../../api/teamApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../config/config';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/ban-types
const JoinTeam = () => {
  const { t } = useTranslation('jointeam');
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<string>();
  const { user, updateUserState } = useStateContext();

  const handleJoinTeam = () => {
    if (selectedTeam)
      joinTeamHandler({ teamId: selectedTeam })
        .then((response) => {
          if (response.status === 200) {
            updateUserState && updateUserState(response.data.user);
            toast.success(t('success'), ToastConfig);
            navigate('/onboarding');
          } else {
            toast.error(response.data.message, ToastConfig);
          }
        });
    else {
      toast.error(t('teamselect'), ToastConfig);
    }
  };

  useEffect(() => {
    getAllTeams()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.length) setTeams(response.data.data);
        }
      });
  }, []);
  
  return (
    <div>
      <div style={{ padding: '2rem 0'}}>
        <Form>
          <Title level={5}>
            <span>{t('teamselect2')}</span>
          </Title>
          <p className='info-onboarding-text'>{t('createteam')}</p>
          <div className='form-group'>
            <IoIosPeople className="select-icon select-icon-left"/>
            <Select className="select-field" style={{ width: '100%' }} onChange={setSelectedTeam}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                teams.filter((team: any) => !(user?.teams.includes(team?._id))).map((team: any) => (<Select.Option value={team._id} key={team._id}>{team.name}</Select.Option>))
              }
            </Select>
          </div>
        </Form>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
          <Button className='custom-btn-ghost' onClick={() => navigate('/onboarding/create-team')}><span>{t('creatteam1')}</span></Button>
          <Button className='custom-btn' onClick={() => handleJoinTeam()}><span>{t('jointeam')}</span></Button>
        </div>
        {
          !!user?.teams?.length && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', paddingRight: '1rem', gap: '2rem' }}>
            <Button
              className='custom-btn-ghost'
              onClick={() => navigate('/onboarding')}
            >
              <span style={{ textTransform: 'capitalize'}}>{t('next')}</span>
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default JoinTeam;