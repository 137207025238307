import React, { ReactNode, useState } from 'react';

interface INavbarContext {
  // eslint-disable-next-line no-unused-vars
  toggleNavbar?(): void;
  isNavbarOpen?: boolean;
}

export const NavbarContext = React.createContext<INavbarContext>({});

export const useNavbarContext = () => React.useContext(NavbarContext);

const NavbarProvider = ({ children }: { children: ReactNode }) => {
  const [isNavbarOpen, setIsNavabarOpen] = useState(false);

  const toggleNavbar = () => {
    // eslint-disable-next-line no-unused-vars
    setIsNavabarOpen(!isNavbarOpen);
  };

  return (
    <NavbarContext.Provider
      value={{
        toggleNavbar,
        isNavbarOpen,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarProvider;
