import React from 'react';
import { Drawer } from 'antd';
// import { BiMenuAltRight } from 'react-icons/bi';
import Title from 'antd/es/typography/Title';
import { useNavbarContext } from '../../context/NavigationContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavbarDrawer = ({ isDefault, children }: { children: any, isDefault: boolean }) => {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();
  const backgroundColor = isDefault ? 'black' : 'var(--color-background-primary)';
  return (
    <Drawer
      style={{
        backgroundColor: backgroundColor,
        color: 'black',
        paddingTop: '2.5rem',
        paddingLeft: '1.5rem',
      }}
      closeIcon={
        <div style={{ display: 'flex', gap: '.5rem' }}>
          <Title level={3} style={{ color: 'black' }}>
            Menu
          </Title>
        </div>
      }
      placement="left"
      onClose={() => toggleNavbar && toggleNavbar()}
      open={isNavbarOpen}
      width="70%"
    >
      { children }
    </Drawer>
  );
};
export default NavbarDrawer;
