import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';


export const EventsComponents = styled.div`
    // padding: 4rem;

    .settings {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }

    .events {
        margin-top: 2rem;
    }

  @media screen and (min-width: ${breakpoints.tablet}) {
        // padding: 8rem;
    }  
`;


