/* eslint-disable @typescript-eslint/no-explicit-any */
import { Empty } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { Container } from './style';
import { useStateContext } from '../../context/StateContext';
import { useTranslation } from 'react-i18next';
import { getAllCancelledEventsByTeam, getAllEventsByTeam, getAllPaidEventsByTeam, getAllUnpaidEventsByTeam } from '../../api/slotApi';
import Spinner from '../../components/Loading/Spinner';
import EventTable from '../../components/EventTable/EventTable';



const UpcomingEvent = () => {
  const { t } = useTranslation('upcoming');
  const { user } = useStateContext();
  const [show, setShow] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<any>(null);

  useEffect(() => {
    switch (show.toLowerCase()) {
    case 'paid':
      if (user?.teams) {
        setIsLoading(true);
        Promise.all((user.teams as unknown as [])?.map(async (teamId: string) => {
          const events = await getAllPaidEventsByTeam({ teamId });
          return events;
        })).then((results) => {
          setEvents(results.flat());
        }).catch((error) => {
          console.error(error);
        }).finally(() => { setIsLoading(false); });
      }
      break;
    case 'unpaid':
      if (user?.teams) {
        setIsLoading(true);
        Promise.all((user.teams as unknown as [])?.map(async (teamId: string) => {
          const events = await getAllUnpaidEventsByTeam({ teamId });
          return events;
        })).then((results) => {
          setEvents(results.flat());
        }).catch((error) => {
          console.error(error);
        }).finally(() => { setIsLoading(false); });
      }
      break;
    case 'cancelled':
      if (user?.teams) {
        setIsLoading(true);
        Promise.all((user.teams as unknown as [])?.map(async (teamId: string) => {
          const events = await getAllCancelledEventsByTeam({ teamId });
          return events;
        })).then((results) => {
          setEvents(results.flat());
        }).catch((error) => {
          console.error(error);
        }).finally(() => { setIsLoading(false); });
      }
      break;
    default:
      if (user?.teams) {
        setIsLoading(true);
        Promise.all((user.teams as unknown as [])?.map(async (teamId: string) => {
          const events = await getAllEventsByTeam({ teamId });
          return events;
        })).then((results) => {
          setEvents(results.flat());
        }).catch((error) => {
          console.error(error);
        }).finally(() => { setIsLoading(false); });
      }
    }
  }, [show, user]);

  return (
    <Container>
      <Title level={5} style={{}}>{t('records')}</Title>
      <div className='capitalize' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'all' ?  700 : 500, cursor: 'pointer'}} onClick={() => setShow('all')}>{t('all')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'paid' ?  700 : 500, cursor: 'pointer'}}  onClick={() => setShow('paid')}>{t('paid')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'unpaid' ? 700 : 500, cursor: 'pointer' }} onClick={() => setShow('unpaid')}>{t('unpaid')}</span>
        <span style={{ fontSize: '1.2rem', fontWeight: show === 'cancelled' ?  700 : 500, cursor: 'pointer'}} onClick={() => setShow('cancelled')}>{t('cancelled')}</span>
      </div>
      {
        isLoading ? <Spinner /> : (
          events.length
            ?
            (events?.map((event: any, idx: number) => {
              return (
                <div key={idx} className='w-full'>
                  <h2 className='font-bold text-xl capitalize'>{event?.data?.team?.name}</h2>
                  { event.status === 200 ? <EventTable events={event.data.data} /> : <Empty className='italic'/>}
                </div>
              );
            }))
            :
            <Empty className='itali' />
        )
      }
    </Container>
  );
};

export default UpcomingEvent;