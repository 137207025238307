import React, { ReactNode, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AccountLogin from './pages/AccountLogin/AccountLogin';
import DefaultLayout from './layouts/DefaultLayout';
import AccountRegister from './pages/AccountRegister/AccountRegister';
import OnBoardingPage from './pages/OnBoarding/OnBoarding';
import BookSession from './pages/BookSession/BookSession';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import PortalPage from './pages/Portal/Portal';
import PortalLayout from './layouts/PortalLayout';
import UpcomingEvent from './pages/UpcomingEvent/UpcomingEvent';
import TeamDetails from './pages/TeamDetails/TeamDetails';
import Payment from './pages/Payment/Payment';
import Profile from './pages/Profile/Profile';
import Home from './pages/Home/Home';
import AccountVerification from './pages/AccountVerification/AccountVerification';
import { ToastContainer, toast } from 'react-toastify';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/RestPassword';
import AddPhoneNumber from './pages/AddPhoneNumber/AddPhoneNumber';
import JoinTeam from './pages/JoinTeam/JoinTeam';
import CreateTeam from './pages/CreateTeam/CreateTeam';
import OnboardingLayout from './layouts/OnboardingLayout';
import { useStateContext } from './context/StateContext';
import { isAuthenticated } from './api/userApi';
import { ToastConfig } from './config/config';
import AdminLayout from './layouts/AdminLayout';
import Admin from './pages/admin/Admin';
import Events from './pages/Events/Events';
import LandingPortal from './pages/LandingPortal/LandingPortal';
import LoadingScreen from './layouts/LoadingScreen';
import ConfirmQrCode from './pages/ConfirmQrCode/ConfirmQrCode';
import AdminUpcomingEvents from './pages/AdminUpcomingEvents/AdminUpcomingEvents';
import AdminCreateAccount from './pages/AdminCreateAccount/AdminCreateAccount';
import AdminSettings from './pages/AdminSettings/AdminSettings';

const WithAdminAuth = ({ children }: { children: ReactNode }) => {
  const { user } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'admin') {
      toast.info('Only Admin Allowed');
      navigate('/login');
    }
  }, []);


  return <>{children}</>;
};

const WithPlayerAuth = ({ children }: { children: ReactNode }) => {
  const { user } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'player') {
      toast.info('Only Players Allowed');
      navigate('/login');
    }
  }, []);

  return <>{children}</>;
};



const Router = () => {
  const { updateUserState } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const protectedRoutes = [
    '/onboarding',
    '/portal',
    '/admin'
  ];

  const adminRoutes = [
    '/admin'
  ];

  useEffect(() => {
    const isProtectedRoute = protectedRoutes.some(route => location.pathname.startsWith(route));
    const isAdminRoute = adminRoutes.some(route => location.pathname.startsWith(route));

    setIsLoading(true);
    isAuthenticated()
      .then(response => {
        if (response.status === 200) {
          updateUserState && updateUserState(response.data.user);
          if (isAdminRoute && response.data.user.role !== 'admin') {
            // toast.info('Only Admin Allowed');
            navigate('/login');
          } else {
            navigate(location.pathname);
          }
        } else {
          if (isProtectedRoute) {
            navigate('/login');
            toast.info('Login Required!', ToastConfig);
          } else {
            navigate(location.pathname);
          }
        }
      }).finally(() => setIsLoading(false));
  }, []);

  const HomeView = () => (
    <DefaultLayout>
      <Home />
    </DefaultLayout>
  );

  const LoginView = () => (
    <DefaultLayout>
      <AccountLogin />
    </DefaultLayout>
  );

  const RegisterView = () => (
    <DefaultLayout>
      <AccountRegister />
    </DefaultLayout>
  );

  const OnBoardingView = () => (
    <WithPlayerAuth>
      <OnboardingLayout>
        <OnBoardingPage />
      </OnboardingLayout>
    </WithPlayerAuth>
  );

  const PortalPageView = () => (
    <WithPlayerAuth>
      <PortalLayout>
        <PortalPage />
      </PortalLayout>
    </WithPlayerAuth>
  );

  const AccountVerificationView = () => (
    <DefaultLayout>
      <AccountVerification />
    </DefaultLayout>
  );

  const ForgotPasswordView = () => (
    <DefaultLayout>
      <ForgotPassword />
    </DefaultLayout>
  );

  const RestPasswordView = () => (
    <DefaultLayout>
      <ResetPassword />
    </DefaultLayout>
  );

  const AdminView = () => (
    <WithAdminAuth>
      <AdminLayout>
        <Admin />
      </AdminLayout>
    </WithAdminAuth>
  );

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/login" element={<LoginView />} />
        <Route path="/register" element={<RegisterView />} />
        <Route path="/account-verification" element={<AccountVerificationView />} />
        <Route path="/forgot-password" element={<ForgotPasswordView />} />
        <Route path="/forgot-password/reset-password/:userId/:email" element={<RestPasswordView />} />
        {/* TODO: Protect Routes */}
        <Route path="/onboarding" element={<OnBoardingView />} >
          <Route path="background" element={<AddPhoneNumber />} />
          <Route path="join-team" element={<JoinTeam />} />
          <Route path="create-team" element={<CreateTeam />} />
        </Route>
        <Route path="/portal" element={<PortalPageView />}>
          <Route path="" element={<LandingPortal />} />
          <Route path="book-session" element={<BookSession />} />
          <Route path="upcoming-events" element={<UpcomingEvent />} />
          <Route path="team-details" element={<TeamDetails />} />
          <Route path="payment" element={<Payment />} />
          <Route path="user-profile" element={<Profile />} />
        </Route>
        <Route path="/admin" element={<AdminView />}>
          <Route path="upcoming-events" element={<AdminUpcomingEvents />} />
          <Route path="register" element={<AdminCreateAccount />} />
          <Route path="settings" element={<AdminSettings />} />
          <Route path="events" element={<Events />} />
          <Route path="events/:qrcode/:teamId/:slotId/:userId" element={<ConfirmQrCode />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Router;
