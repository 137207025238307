import React, { ReactNode } from 'react';
import { Button, Modal } from 'antd';

const ModalComponent = (
  { open, handleOk, handleCancel, loading, children, title }:
  // eslint-disable-next-line @typescript-eslint/ban-types
  {open: boolean, handleOk: Function, handleCancel: Function, loading: boolean, children: ReactNode, title?: string | ReactNode}
) => {
  return (
    <Modal
      open={open}
      title={title}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
            OK
        </Button>,
      ]}
    >
      { children }
    </Modal>
  );
};

export default ModalComponent;