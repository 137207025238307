import React, { ReactNode, useState } from 'react';

type MyObject<T> = {
  [key: string]: T;
};

interface IState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: MyObject<string>,
  updateUserState?: (userToSet: MyObject<string>) => void
}

export const StateContext = React.createContext<IState>({});

export const useStateContext = () => React.useContext(StateContext);

const StateProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<MyObject<string>>();

  const updateUserState = (userToSet: MyObject<string>) => {
    // eslint-disable-next-line no-unused-vars
    setUser(userToSet);
  };

  return (
    <StateContext.Provider
      value={{
        updateUserState,
        user
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
