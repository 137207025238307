import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const defaultLanguage = 'en';

interface ILanguageContext {
  // eslint-disable-next-line no-unused-vars
  changeLanguage?(translatedTo: 'en' | 'fr' | 'rw'): void;
  language?: 'en' | 'fr' | 'rw';
}

export const LanguageContext = React.createContext<ILanguageContext>({});

export const useLanguageContext = () => React.useContext(LanguageContext);

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<'en' | 'fr' | 'rw'>(defaultLanguage);
  const { i18n } = useTranslation();

  const changeLanguage = (translatedTo: 'en' | 'fr' | 'rw') => {
    // eslint-disable-next-line no-unused-vars
    setLanguage(translatedTo);
    i18n.changeLanguage(translatedTo);
  };
    
  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, []);


  return (
    <LanguageContext.Provider
      value={{
        changeLanguage,
        language,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
